// =============================================
// Cart / Panier
// =============================================

.checkout-cart-index {
    // Empty
    .cart-empty {
        background-color: $c-project4;
        max-width:  700px;
        margin:     100px auto 150px;
        padding:    20px;
        line-height:    20px;
        p {
            a {
                text-decoration: underline;
                &:hover { }
            }
        }
    }
    // Content
    .wishlist-banner {
        background:    url(../images/banners/wishlist-banner.jpg) 50% 0 no-repeat;
    }
    .page-title {
        &.page-empty-title {
            max-width:  700px;
            margin:     0 auto;
            text-align: left;
        }
    }
    .cart {
        .data-table {
            margin: 50px 0 0;
            thead {
                th {
                    border-bottom:  2px solid $c-black;
                    font-size:      14px;
                    font-weight:    bold;
                    padding:        5px 10px 20px;
                    text-transform: uppercase;
                }
            }
            tbody {
                td {
                    border-bottom: 1px solid $c-black;
                    padding:       5px 10px;
                    &.last { text-align: right; }
                }
                .product-image {
                    display:        inline-block;
                    margin:         0 10px 0 0;
                    vertical-align: middle;
                }
                .product-name-content {
                    display:        inline-block;
                    vertical-align: middle;
                }
                .product-name {
                    font-weight: bold;
                }
                .product-sku {
                    color:  $c-grey;
                }
                .color-content {
                    color:  $c-grey;
                    img {
                        margin: 0 10px 0 0;
                        @include border-radius(100%);
                    }
                }
                .item-options {
                    border:         2px solid #000000;
                    display:        inline-block;
                    font-weight:    bold;
                    height:         25px;
                    line-height:    24px;
                    padding:        0 5px;
                }
                .qty {
                    .customdroptext {
                        background-color:   $c-white;
                        border:             1px solid $c-input-border;
                    }
                }
                .price-unit {
                    font-weight: bold;
                }
                .stock-content {
                    font-weight: bold;
                    .in-stock { color:  $c-project1;}
                    .out-of-stock { color:  $c-project5;}
                }
                .btn-remove {
                    border:         2px solid $c-black;
                    font:           500 1.8rem;
                    display:        block;
                    height:         32px;
                    line-height:    32px;
                    text-align:     center;
                    vertical-align: middle;
                    width:          32px;
                    @include border-radius(50%);
                    @include transition(all 250ms ease-in-out);
                    &:hover { border-color: rgba($c-black, 0.5); }
                }
            }
        }
        // Totals
        .total-container {
            float: right;
            width: 50%;
            .discount {
                label { }
                .input-box { }
                .input-text { }
                .button {
                    &.cancel-btn { }
                }
            }
            .shopping-cart-totals-table {
                margin: 0 0 20px;
                tr {
                    td { border-bottom: 1px solid $c-black; }
                    &.grand_total-total {
                        td { background-color: $c-grey; }
                    }
                }
            }
        }
        .buttons-set {
            margin: 0 0 25px;
            .button { }
        }
    }
    .block-favorite-store {
        background:     $c-grey-light;
        display:        inline-block;
        min-width:      440px;
        min-height:     150px;
        padding:        30px 30px 25px 70px;
        position:       relative;
        vertical-align: middle;
        width:          40%;
        .icon {
            font-size:  30px;
            position:   absolute;
            left:       30px;
            top:        20px;
        }
        .button {
            letter-spacing: 1px;
            position:       absolute;
            right:          30px;
            top:            30px;
            text-transform: uppercase;
        }
    }
    .no-favorite-store-footer {
        position:   relative;
    }
    .cart-links {
        display:        inline-block;
        padding:        0 0 0 5%;
        text-align:     center;
        vertical-align: middle;
        width:          50%;
    }
    .print-cart {
        display:    inline-block;
        margin:     5px 10px;
        width:      250px;
        .icon {
            margin: 5px 10px 0 0;
        }
    }
    .fb-share-cart{
        display:    inline-block;
        margin:     5px 10px;
        width:      250px;
        .icon {
            margin: 5px 0 0 5px;
        }
    }
}

//
// Responsive
//
@include respond-to(medium) {
    .checkout-cart-index {
        .cart-links {
            width:  300px;
        }
    }
}
@include respond-to(800px) {
    .checkout-cart-index {
        .block-favorite-store {
            margin: 0 0 50px;
            width:  100%;
        }
        .cart-links {
            text-align: right;
            width:      100%;

        }
        .print-cart {
            margin: 0 10px 0 0;
        }
        .fb-share-cart {
            margin: 0;
        }
    }
}
@include respond-to(small) {
    .checkout-cart-index {
        .cart .data-table {
            display:    block;
            overflow:   auto;
        }
        .wishlist-banner {
            margin-bottom: 20px;
        }
    }
}
@include respond-to(600px) {
    .checkout-cart-index {
        .block-favorite-store {
            min-width: 1px;
            .button {
                position: static;
            }
        }
        .cart-links {
            padding: 0;
        }
        .print-cart , .fb-share-cart {
            display:    block;
            margin:     10px auto 0;
        }
    }
}
