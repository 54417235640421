// =============================================
// Draw svg stroke
// =============================================
@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

// =============================================
// Fill svg fill
// =============================================
@keyframes fill-in {
    to {
        fill-opacity: 1;
    }
}

// =============================================
// Rotate and scale
// =============================================
@keyframes roll {
    0% {
      transform: rotate(0) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(.8);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
}