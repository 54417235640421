// =============================================
// Gabarit de page
// =============================================

// Transition VueJS
.technoBlock-transition {
    transition: all .5s ease;
    overflow: hidden;
}
.technoBlock-enter, .technoBlock-leave {
    height: 0;
    opacity: 0;
}


.video {
    position: relative;
    padding-bottom: 47.5%;
    padding-top: 1.5%;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


// GLOBAL CONTENT
.page-installation-container,
.page-entretien-container,
.page-situation-container,
.page-depannage,
.cms-page-view,
.page-pro-template {
    // PAGE BANNER
    .page-banner {
        font-family: $montserrat;
        height: 420px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: $c-white;
        .h1-title {
            margin: 0 auto;
            max-width: 675px;
        }
        .catch-phrase {
            margin: 20px auto 45px;
            max-width: 675px;
            font-size: 1.4rem;
        }
        .banner-content {
            text-align: center;
            position: absolute;
            left: 20px;
            right: 20px;
            top: 50%;
            @include transform(translateY(-50%));
        }
        .banner-links {
            .button {
                display: inline-block;
                margin: 0 20px 15px;
                max-width: 260px;
                vertical-align: top;
                height: 45px;
                padding: 6px 20px;
                line-height: normal;
            }
        }
        .button-content {
            display: block;
            position: relative;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}

.page-installation-container,
.page-entretien-container,
.page-situation-container,
.page-depannage,
.cms-page-view,
.page-pro-template {

    //PAGE
    .page-content {
        span.color { color: $c-project1; }
        .list {
            display: block;
            font-size: 1.4rem;
            padding: 0 0 0 20px;
            position: relative;
            margin: 0 0 10px;
            &:before {
                content:"\EA0F";
                color: $c-project1;
                display: block;
                font-family: "iconfont";
                font-size:  .8rem;
                position: absolute;
                left: 0;
                top: 6px;
                @include border-radius(100%);
            }
        }
    }
    .container {
        margin: 0 auto 80px;
        display: table;
        max-width: 1000px;
        width:      100%;
        .h2-title {
            letter-spacing: 1px;
        }
        .picture, .details {
            display: table-cell;
            vertical-align: middle;
            width :50%;
            padding: 0 30px;
        }
        .picture {
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        .details { max-width: 380px; }
        .title {
            font-size: 1.2rem;
            text-transform: uppercase;
            color: $c-project4;
        }
        .catch-phrase {
            font-size: 2rem;
            span { color: $c-project1; }
        }
        .text {
            margin: 0 0 20px;
            &:after {
                background-color: $c-black;
                content:"";
                display: block;
                height: 1px;
                margin: 30px 0 0;
                width: 10%;
            }
            span {
                color: $c-project1;
            }
        }
        .link {
            color: $c-project1;
            cursor: pointer;
            font-size: 1.4rem;
            text-transform: uppercase;
            &:hover {
                color: lighten( $c-project1, 20% );
            }
        }
    }
    .picture-right {
        .details { padding: 0 30px 0 60px;}
    }
    .picture-left {
        .details { padding: 0 60px 0 30px;}
    }
    .block-grey {
        background: $c-project5;
        padding: 60px 100px;
        margin: 0 auto 55px;
        max-width: 1360px;
        .h2-title { text-align: center; }
    }
    .block-grey-dark {
        background: #ECECEC;
        padding: 65px 100px;
        margin: 0 auto 55px;
        max-width: 1360px;
        .h2-title { text-align: center; }
    }
}



.page-installation-container,
.page-entretien-container,
.page-situation-container,
.page-depannage {
    font-family: $montserrat;
    // PAGE
    .page-content {
        margin: 40px auto;
    }
    // BANNER PARTNERS
    .banner-partners {
        background: $c-project5;
        font-size: 0;
        padding: 35px 20px;
        text-align: center;
        width: 100%;
        .content {
            max-width: 1320px;
            margin: 0 auto;
        }
        .desc-partner {
            border-right: 1px solid #c9c9c9;
            color: $c-project8;
            display: inline-block;
            vertical-align: middle;
            font-family: $montserrat;
            font-size: 1.1rem;
            padding: 0 35px 0 0;
            max-width: 270px;
            text-transform: uppercase;
            text-align: right;
            width: 20%;
        }
        .item-partners {
            opacity: 0.45;
            display: inline-block;
            vertical-align: middle;
            width: 80%;
            &.by-8 {
                .item {
                    width: 12.5%;
                }
            }
        }
        .item {
            display: inline-block;
            padding: 0 20px;
            vertical-align: middle;
            width: 20%;
        }
    }

    // Tips & Tricks
    .tips-tricks {
        padding: 30px 0 0;
        display: none;
        .h2-title {
            font-size: 2.6rem;
            text-transform: none;
            margin: 0 0 30px;
            text-align: center;
        }
    }

    // PAGE INSTALLATION
    .techno-container {
        padding: 65px 100px 100px;
        .tab {
            text-align: center;
        }
        .h2-title { margin: 0 0 20px;}
        .tab {
            color: $c-project4;
            margin: 0 0 75px;
            text-transform: uppercase;
            .item {
                cursor: pointer;
                display: inline-block;
                padding: 0 25px;
                @include transition(all .3s linear);
                &:hover { color:$c-project2; }
                &.active {
                    color:$c-project2;
                    position: relative;
                    &:after {
                        background: $c-project9;
                        content:"";
                        display: block;
                        height: 3px;
                        position: absolute;
                        bottom: -10px;
                        left: 25px;
                        right: 25px;
                    }
                }
            }
        }
        .picture {
            float: left;
            width: 20%;
            padding: 0 20px 0 0;
        }
        .desc {
            padding: 0 100px 0 20px;
            float: left;
            width: 50%;
            color: $c-project2;
        }
        .feature {
            border-left: 1px solid #c9c9c9;
            padding: 50px 0 90px 30px;
            text-transform: uppercase;
            float: left;
            width: 30%;
            .item {
                display: block;
                position: relative;
                margin: 0 0 25px;
                padding: 0 0 0 30px;
                &:before {
                    background-image:   url("../images/check.png");
                    content:"";
                    display: block;
                    width: 17px;
                    height: 13px;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
            }
        }
        .button {
            width: auto;
            display: inline-block;
            margin: 35px 0 0;
        }
    }

    // PAGE ENTRETIEN
    .desc-obligation {
        font-size: 0;
        padding: 65px 100px;
        text-align: center;
        margin: 0 auto;
        &.terminals {
            padding: 65px 200px;
            .item {
                width: 33%;
            }
        }
        .h2-title {
            margin: 0 0 70px;
        }
        .item {
            display: inline-block;
            width: 25%;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            margin: 0 0 60px;
            font-size: 1.1rem;
            text-transform: uppercase;
            img { max-height: 54px; }
        }
        .text {
            max-width: 170px;
            margin: 20px auto;
        }
        .button {
            //display: inline-block;
            display: none;
            width: auto;
            margin: 0 auto;
        }
    }
    .take-appointment { margin: -50px auto 0; }
    // Contracts
    .contract-gas,
    .contract-fioul,
    .contract-terminal {
        padding: 50px 0 0;
        display: none;
        .h2-title {
            color: $c-white;
            font-size: 2.6rem;
            font-weight:normal;
            text-transform: none;
            margin: 0 0 50px;
            text-align: center;
            &:before {
                content:"";
                display: block;
                margin: 0 auto 20px;
                width: 19px;
                height: 29px;
            }
        }
    }
    .contract-gas,
    .contract-fioul {
        background:      $c-project5 url("../images/banner-contract.jpg") no-repeat;
        background-size: contain;
        .h2-title:before {
            background: url("../images/flame.png") no-repeat;
        }
    }
    .contract-terminal {
        position: relative;
        &:before {
            content:"";
            display: block;
            background: $c-project11;
            width: 100%;
            height: 540px;
            position: absolute;
            top: 0;
            z-index:-1;
        }
        .h2-title:before {
            background: url("../images/prise.png") no-repeat;
        }
    }


    .push-product-content {
        max-width: 1055px;
        margin: 0px auto;
        .product-items {
            font-size: 0;
            text-align: center;
            .price-box .price { color: $c-project10; }
        }
    }
    // End Page Entretien

    // PAGE DEPANNAGE
    .howitwork-container {
        text-align: center;
        .h2-title { margin: 0 0 30px; }
        span.price {
            color: $c-project1;
            font-size: 2.8rem;
        }
        .content {
            text-align: center;
            img {
                display: inline-block;
                vertical-align: middle;
            }
            .plus { margin: 0 50px; }
        }
        .text {
            max-width: 620px;
            margin: 30px auto;
        }
        .button {
            padding: 0 40px;
        }
    }
    //End Page Depannage

    // PAGE SITUATION
    .situation-content {
        .container { margin: 0 auto; }
        .block-faq,
        .discovery-container {
            .h2-title { margin: 0; }
        }
        .block-faq { text-align: center; }
        .page-faq {
            margin: 40px 0 0;
            text-align: left;
        }
        .faq-content {
            max-width:  870px;
            margin:     0 auto;
            padding:    0 10px;
        }
        .category {
            background: $c-white;
            @include border-radius(5px);
            display:    table;
            max-width:  1046px;
            margin:     0 auto;
            padding:    0 10px;
            position:   relative;
            text-align: center;
            top:        -40px;
            width:      100%;
        }
        .category-item {
            color:      $c-project2;
            display:    table-cell;
            font-family:$montserrat;
            padding:    30px 0 20px;
            &:after {
                content:    "";
                background: $c-project5;
                display:    block;
                height:     4px;
                margin:     10px auto 0;
                width:      0px;
                @include border-radius(5px);
                @include transition(width 0.5s ease-in-out);
            }
            &:hover:after {
                width:      30%;
            }
            &.active {
                &:after {
                    width:      30%;
                }
            }
        }
        .discovery-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px 0 0;
            text-align: center;
            .label {
                font-size: 2.6rem;
                font-weight: bold;
                margin: 0;
            }
            .block {
                display: inline-block;
                vertical-align: middle;
                margin: 50px 0;
                padding: 0 10px;
                width: 30%;
            }
            .block-content {
                background: $c-project5;
                padding: 30px;
                min-height: 350px;
            }
            .text {
                color: $c-project3;
                span { color: $c-project1; }
            }
            .list-container {
                background: $c-white;
                margin: 30px 0;
                padding: 10px 30px;
                text-align: left;
            }
            .list {
                font-family: $open;
                font-size: 1.3rem;
                color: $c-project12;
                font-weight:600;
                margin: 15px 0;
            }
            .button {
                display:        inline-block;
                margin:         0 20px;
                max-width:      260px;
                vertical-align: top;
                height:         auto;
                padding:        10px 20px;
                line-height:    normal;
            }
        }
    }

}


//
// One shot product
//
.one-shot{
    height: 340px;
    margin: 0 0 50px;
}
.one-shot-content{
    font-family: $open;
    max-width: 890px;
    margin: 0 auto;
    position: relative;
    padding: 75px 350px 0 20px;
    .price-excluding-tax { display: none; }
}
.one-shot-title{
    font-size: 2.3rem;
    font-style: italic;
    font-weight: 600;
    margin: 0 0 5px 0;
    font-family: $open;
}
.one-shot-price{
    font-size: 2.4rem;
    font-family: $montserrat;
    margin: 0 10px 15px 0;
    color: $c-project2;
    display: inline-block;
}
.one-shot-list{
    border-top: 1px solid $c-project4;
    padding: 17px 0;
    display: table;
    width: 97%;
    li{
        display: table-cell;
        font-size: 1.5rem;
        font-weight: 600;
        &:last-child{
            text-align: right;
        }
        .icon{
            font-size: 1.3rem;
            margin: 0 10px 0 0;
        }
    }
}
.price-box { display: inline-block; }
.asterix{
    display: block;
    padding: 10px 0 0 0;
    font-size: 1.5rem;
}
.one-shot-right{
    position: absolute;
    top: 80px;
    right: 20px;
    width: 260px;
    background: $c-white;
    @include border-radius(5px);
    text-align: center;
    padding: 25px 0;
    .button{
        max-width: 175px;
        margin: 0 auto;
    }
}
.one-shot-right-title{
    display: inline-block;
    font-family: $montserrat;
    font-size: 1.8rem;
    color: $c-project2;
    margin: 0;
}
.one-shot-right-subtitle{
    display: block;
    font-size: 1.3rem;
    color: $c-project3;
    margin: 0 0 20px 0;
}

// Asterisk
.asterisk-character {
    font-weight:    bold;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
}


//
// Gabarit Professionnel
//

.page-installation-container,
.page-situation-container {
    &.page-pro-template .container { max-width: 1000px; }
}

.page-pro-template {
    .page-banner {
        height: 380px;
    }
    .page-content { margin: 80px auto; }
    .container-bg {
        background: $c-project5;
        padding: 100px 0;
        margin: 0 0 80px;
        &.triptych { padding: 60px 0; }
        .container { margin: 0 auto; }
    }
    .container {
        max-width: 1360px;
        &.full { max-width: 1380px; }
        &.container.table {
            padding: 0 20px;
            text-align: center;
            font-size: 1.3rem;
            display: block;
            .h3-title {
                font-size: 3rem;
            }
            .content-table {
                margin: 40px auto 0;
                max-width: 940px;
                max-height: 720px;
                overflow: auto;
                .icon { color: $c-project10; }
                .kiwhi {
                    background: url("../images/professionnels/kiwhi.png") no-repeat;
                    width: 48px;
                    height: 40px;
                    display: block;
                    margin: 0 auto;
                }
            }
            table {
                table-layout: fixed;
                overflow: scroll;
            }
            thead {
                td {
                    background: $c-project12;
                    border-right: 1px solid $c-white;
                    color: $c-white;
                    &:first-child { width: 260px; }
                    &:last-child { border-right: none; }
                    position: relative;
                }
            }
            tbody {
                tr:nth-child(odd) td {
                    background: $c-project15;
                }
                td {
                    background: $c-grey-light;
                    border-right: 1px solid $c-project4;
                    &:first-child {
                        width: 260px;
                        text-align: left;
                        padding: 10px 10px 10px 30px;
                        white-space: nowrap;
                    }
                    &:last-child { border-right: none; }
                }
            }
            tr td { min-width: 150px; }


        }
        .offer {
            max-width: 1150px;
            margin: 0 auto;
            padding: 0 20px;
            .blocks { margin: 80px 0 0; }
            h3 {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                color: $c-project2;
            }
        }
        .catch-phrase.big {
            font-size: 3.4rem;
            font-weight: bold;
            margin: 20px 0;
        }
        .details,
        .media {
            padding: 0 20px;
        }
    }
    .block-faq {
        max-width: 1130px;
        .subtitle {
            font-size: 2rem;
            text-align: center;
            display: block;
            margin: 0 0 30px;
        }
    }
}

@include respond-to(medium) {
    // GLOBAL CONTENT
    .page-installation-container,
    .page-entretien-container,
    .page-situation-container,
    .page-depannage {

        .banner-partners {
            background: $c-project5;
            font-size:  0;
            padding:    35px 20px;
            text-align: center;
            width:      100%;
        }
        .techno-container {
            padding: 40px 60px;
        }
    }
}

@include respond-to(small) {
    .page-installation-container,
    .page-entretien-container,
    .page-situation-container,
    .page-depannage,
    .cms-page-view,
    .page-pro-template {
        // PAGE BANNER
        .page-banner {
            background-position: 30% center;
            height: 400px;
            .h1-title { font-size: 1.8rem; }
            .catch-phrase { font-size: 1.3rem; }
        }
        .banner-links {
            .button {
                width: 100%;
                margin: 0 0 15px;
                &:last-child { margin: 0; }
            }
        }
        .container-bg {
            padding: 30px 0;
        }
        .container {
            display:   block;
            &.full {
                > div { margin: 0 0 30px; }
                img {
                    display: block;
                    margin: 0 auto;
                }
            }
            .picture,
            .details {
                display: block;
                margin: 0 0 20px;
                max-width: none;
                padding: 0 10px;
                width: 100%;
                img { max-width: 250px; }
            }
            .catch-phrase { font-size: 1.6rem; }
            .text { font-size: 1.3rem; }
        }
        .block-grey {
            padding: 40px 10px;
        }
    }

    .page-pro-template {
        .container-bg { margin: 0 0 40px;}
        .container {
            margin: 0 auto 40px;
            .picture,
            .details {
                img { max-width: 100%; }
            }
        }
        .page-content { margin: 40px auto; }
        .block-faq {
            .subtitle {
                margin: 0 auto 30px;
                max-width: 270px;
            }
            .page-faq { padding: 0 10px; }
        }
    }

    .page-installation-container,
    .page-entretien-container,
    .page-situation-container,
    .page-depannage {
        .techno-container {
            padding: 40px 10px;
            margin: 0 auto;
            .tab {
                max-width: 275px;
                margin: 0 auto 45px;
                .item {
                    margin: 0 0 20px;
                }
            }
            .picture,
            .desc,
            .feature {
                float: none;
                display: block;
                width: 100%;
                margin: 0 auto;
                padding: 0;
                .button {
                    display: block;
                    margin: 35px auto;
                    max-width: 300px;
                }
            }
            .picture {
                text-align: center;
                img { max-width: 250px; }
            }
            .feature { border: none; }
        }
        .situation-content {
            .discovery-container {
                .block {
                    display: block;
                    margin:  20px 0;
                    width:   100%;
                }
            }
        }
        .desc-obligation {
            font-size:  0;
            padding:    65px 20px;
            text-align: center;
            margin:     0 auto;
            &.terminals {
                .item {
                    width: 50%;
                }
            }
            .item {
                display:        inline-block;
                width:          50%;
                padding:        0 20px;
                text-align:     center;
                vertical-align: top;
                margin:         0 0 60px;
                font-size:      1.1rem;
                text-transform: uppercase;
                img { max-height: 54px; }
            }
        }
        .banner-partners {
            padding: 20px;
            .desc-partner {
                border:     none;
                display:    block;
                max-width:  none;
                width:      100%;
                text-align: center;
            }
            .item-partners {
                opacity:        0.45;
                display:    block;
                vertical-align: middle;
                width:          100%;
            }
            .item {
                padding: 0 20px 0 0;
                &:last-child { padding: 0; }
            }
        }
        .howitwork-container {
            .content {
                img { max-width: 100px; }
                .plus {
                    margin: 0 10px;
                    max-width: 30px;
                }
            }
        }
    }
}