// =============================================
// Footer
// =============================================
.page-footer{
    background: $c-grey-1;
    padding: 0 10vw;
    position: relative;
}
.footer.content {
    
    //max-width: $largeurSite;
    //margin: 0 auto;
    padding-top: 75px;
    padding-bottom: 100px;

    //Reviews 
    .reviews-widget {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }

    //Logo/Simulator
    .footer-left {
        margin-bottom: 60px;

        .simulator-container {
            width: 270px;
            margin-top: 50px;
            padding: 30px;
            border: 1px solid $c-grey-2;
            border-radius: 10px;
            
            p {
                font-family: $roboto-b;
                font-size: 2.2rem;
                color: $c-white;
                line-height: 2.5rem;
                margin-bottom: 25px;
            }

            .btn {
                border: 1px solid $c-grey-2;
            }
        }
    }

    //Links
    .footer-links_container {
        width: 100%;
        
        //Common
        .list .title {
            display: block;
            margin-bottom: 20px;
            font-family: $roboto-b;
            font-size: 1.7rem;     
        }

        .footer-links-content {
            width: 50%;
        }

        .links li{
            margin: 10px 0;

            a {
                color: $c-grey-5;
                font-size: 1.5rem;
                
            }

            &:hover {
                a {
                    color: $c-main;
                }
            }
        }

        //Left column
        .footer-left-content {
            display: flex;

            .list {
                margin-bottom: 50px;
                padding-right: 10px;

               .title {
                    color: $c-main;
                } 
            }
        }

        //Right column
        .footer-right-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .list {
                width: 50%;

                .title {
                    color: $c-grey-3;
                }
            } 

            .footer-links-content {
                display: flex;
                width: 100%;
                
            }

            .all-rights-reserved {
                margin-top: 50px;
                color: white;
                text-align: center;
            }
        }
    }

    //Bottom
    .footer-bottom_container {
        position: absolute;
        padding: 0 10vw;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #020A0E;
        height: 90px;
        

        *:not(div) {
          color: $c-grey-3;  
        }

        li {
            margin: 5px 0;
        }

        div {
            color: $c-grey-2;
        }
        
    }

}

//
// Responsive
//

/* ----------------- Screen 1024px
*/
@include breakpoint(lg) {
    .page-footer {
        .footer.content {
            >div {
                display: flex;
                justify-content: space-between;
                gap: 4vw;
            }

            //Reviews/Logo/Simulator
            .footer-left{
                margin-bottom: 0;
                padding-bottom: 50px;
            }

            //Links
            .footer-links_container {
                &>div {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 100px;
                    height: calc(100% - 100px);
                }

                .footer-left-content,
                .footer-right-content {
                    width: 50%;
                }

                //Left column
                .footer-left-content {
                    justify-content: space-between;
                }

                //Right column
                .footer-right-content {
                    .list {
                        width: auto;
                    }
                    
                    .footer-links-content {
                        justify-content: space-around;
                    }

                    .all-rights-reserved {
                        text-align: end;
                    }
                }
                
            }

            //Bottom
            .footer-bottom_container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                ul {
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                }
            }
        }
    }
}


//
// Proxiserve Professionnels
//
.footer.content {
    .only-mobile { display: none; }
    .pro-footer {
        position: absolute;
        bottom: 0;
        .footer-links-content { margin: 0 0 50px; }
        .list {
            .title {
                min-height:     40px;
                max-width:      225px;
                letter-spacing: 1px;
                border-bottom: 1px solid transparent;
                @include transition(border .3s linear);
                &:hover { border-color: $c-white; }
            }
        }
        .service-list {
            top: 0;
            .info {
                color: $c-project7;
                display: block;
                margin: 10px 0 0;
                max-width: 130px;
                font-size: 1.2rem;
                text-transform: none;
            }
            span { letter-spacing: 0; }
        }
        .social-icons {
            li {
                display: inline-block;
                &:nth-child(2) {
                    margin: 0 35px;
                }
                a {
                    display: block;
                    text-indent: -9999px;
                    &:after {
                        font-family: 'iconfont';
                        display: block;
                        text-indent: 0;
                        color: $c-white;
                        font-size: 3.5rem;
                        padding: 0 0 10px;
                        position: relative;
                        top: -20px;
                        @include transition(color .3s linear);
                    }
                    &:hover:after {
                        border-bottom: 1px solid;
                        position: relative;
                    }
                }
            }
        }
        .link-youtube:after { content:"\EA15"; }
        .link-facebook:after { content:"\EA10"; }
        .link-rss:after { content:"\EA16"; }
    }
}

@include respond-to(small) {
    .footer.content {
        .only-mobile { display: block; }
        .footer-right { min-height: auto; }
        .pro-footer {
            position: static;
            padding: 25px 40px 0;
            .footer-logo {
                margin: 0 auto 40px;
                max-width:  170px;
            }
            .footer-links-content {
                margin: 0 auto;
                max-width:  250px;
            }
            .list {
                border: none;
                .title {
                    display: inline-block;
                    min-height: initial;
                    margin: 0;
                    &:hover { border-color: $c-grey-dark; }
                }
            }
            .service-list {
                margin: 20px 0;
                width: auto;
                .title {
                    color: $c-white;
                    margin: 0 0 20px;
                }
                .info {
                    font-size: 1rem;
                    max-width: none;
                    position: relative;
                    left: -30px;
                }
            }
            .social-icons {
                margin: 0;
                .links {
                    opacity: 1;
                    max-height: none;
                    text-align: center;
                }
                li {
                    margin: 0;
                    a:after {
                        font-size: 2.2rem;
                    }
                }
            }
        }
        .footer-left {
            .simulator-container {
                width: 100%;
            }
        }
    }
}
