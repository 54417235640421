// =============================================
// BLOG PAGE
// =============================================

.contentmanager-contentlist-view .toolbar-bottom{
    display: none;
}
//
// LISTING
//
.contentmanager-contentlist-view {
    .block-title { display: none; }
    .blog-banner {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 600px;
        position: relative;
        z-index: -1;
    }
    .text-before { position: relative; }
    .page-title {
        color: $c-white;
        font-family: $montserrat;
        font-size: 1.4rem;
        padding: 0 20px;
        position: absolute;
        top: 20%;
        left: 50%;
        width: 100%;
        @include transform( translateX(-50%));
        p {
            max-width: 675px;
            margin: 0 auto;
        }
    }
    .contents-items {
        max-width: 900px;
        margin: -265px auto 50px;
        position: relative;
        z-index: 1;
    }
    .content-item {
        margin: 0 auto 30px;
        width: 100%;
        &:after {
            display: block;
            content: ".";
            clear: both;
            font-size: 0;
            line-height: 0;
            height: 0;
            overflow: hidden;
        }
        .push {
            padding: 0 10px;
            width: 37%;
            &.youtube {
                color: $c-white;
                text-align: center;
                .content {
                    padding: 40px 20px;
                    position: relative;
                    max-width: 268px;
                    width: 100%;
                }
                .bg-youtube {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: -1;
                }
                .icon {
                    font-size: 6rem;
                }
                .catch-phrase {
                    font-size: 1.3rem;
                    font-weight: 600;
                    margin: 35px 0 30px;
                    text-transform: uppercase;
                    span { font-size: 1.6rem; }
                }
                .button {
                    background: $c-white;
                    border-color: $c-white;
                    color: $c-project2;
                    &:hover {
                        background: transparent;
                        color: $c-white;
                    }
                }
            }
        }
        .article .content {
            text-align: left;
        }
        &.left {
            .push { float: right; }
            .article {
                float: left;
                text-align: left;
            }
        }
        &.right {
            .push { float: left; }
            .article {
                float: right;
                text-align: right;
            }
        }
        .menu {
            .content {
                background: rgba($c-project3, 0.95);
                color: $c-white;
                font-family:    $montserrat;
                padding: 55px 20px 55px 50px;
                text-transform: uppercase;
                a {
                    color: $c-white;
                    display: block;
                }
                .see-all {
                    font-size: 1.8rem;
                    font-weight: bold;
                    margin: 40px 0 0;
                    &:hover {
                        color: $c-project1;
                    }
                }
            }
        }
        .menu-title {
            font-size: 1.2rem;
            letter-spacing: 1px;
            &:after {
                background: $c-white;
                content:"";
                display: block;
                height: 1px;
                margin: 10px 0 25px;
                width: 75px;
            }
        }
        .list-theme {
            font-size: 1.4rem;
            .item {
                margin: 0 0 15px;
                a {
                    position: relative;
                    &:after {
                        content: "\25b6";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        opacity: 0;
                        @include transition(opacity .3s linear);
                    }
                    &:hover:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .icon-scroll {
        top: 265px;
        margin: 0;
    }


}


//
// ARTICLE
//
.page-article {
    font-family: $montserrat;
    font-size: 1.2rem;
    text-align: center;
    .page-banner {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: $c-white;
        font-family: $montserrat;
        height: 420px;
        position: relative;
        z-index: -1;
    }
    .page-content {
        background: $c-white;
        max-width: 900px;
        padding: 35px 45px;
        margin: -85px auto 0;
    }
    .article {
        max-width: 670px;
        margin: 0 auto;
    }
    .back-container {
        margin: 0 0 25px;
        padding: 0 0 0 20px;
        text-align: left;
        position: relative;
        .link {
            color: #bfbfbf;
            font-size: 1.6rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            &:before {
                content:"\EA06";
                font-family: 'iconfont';
                font-size: 0.5rem;
                @include transform(rotate(90deg));
                display: block;
                position: absolute;
                top: 6px;
                left: 0;
            }
        }
    }
    .theme {
        color: $c-project4;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 15px;
    }
    .title {
        font-size: 2.2rem;
        color: $c-project13;
        text-transform: uppercase;
    }
    .sub-title {
        color: $c-project2;
        font-size: 1.8rem;
        margin: 40px 0;
        span { color: $c-project13; }
    }
    .desc {
        color: $c-project2;
        font-size: 1.4rem;
        margin: 0 0 50px;
        text-align: left;
        span,
        a {

        }
    }
    .pictures-container {
        .figure {
            float: left;
            width:  50%;
        }
        .first { padding: 0 10px 0 0; }
        .second { padding: 0 0 0 10px; }
    }
    .figure {
        margin: 0 auto 30px;
        max-width: 440px;
    }
    .legend {
        color: $c-project4;
        font-size: 1.2rem;
        margin: 10px 0 0;
    }
    .block-grey {
        background: $c-project5;
        margin: 80px auto 50px;
        padding: 55px 10px;
        .desc {
            margin: 0 auto 30px;
            max-width: 670px;
            text-align: center;
        }
    }

    .social-container {
        margin: 50px auto;
        text-align: center;
    }
    .social-link {
        color: #828282;
        display: inline-block;
        border: 1px solid;
        border-color: #dbdbdb;
        @include border-radius(100%);
        padding: 20px;
        margin: 0 10px;
        font-size: 22px;
        position: relative;
        height: 48px;
        width: 48px;
        &:hover {
            color: $c-project1;
        }
        .icon:before {
            position:absolute;
            top: 12px;
            left: 12px;
        }
    }
}


@include respond-to(small) {
    .contentmanager-contentlist-view {
        .blog-banner {
            background-size:     cover;
            background-position: center;
            height:              300px;
            position:            relative;
            z-index:             -1;
        }
        .contents-items {
            margin: 0 auto 50px;
        }
        .content-item {
            margin: 0 auto;
            &.left,
            &.right {
                .push {
                    float: none;
                    margin: 0 auto 20px;
                    width: 100%;
                }
                .article {
                    float: none;
                    margin: 0 auto 20px;
                    max-width: 575px;
                    width: 100%;
                }
            }
            .push {
                &.menu {
                    padding: 0;
                    .content { padding: 30px 20px 30px 40px; }
                }
                &.youtube .content {
                    margin: 0 auto;
                }
            }
        }
    }


    .page-article {
        .page-banner {
            background-position: 45% center;
            height:              400px;
        }
        .pictures-container {
            .figure {
                float: none;
                width:  100%;
                padding: 0
            }
        }
    }
}
