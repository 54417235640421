
.modals-wrapper:has(._show) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-popup {
        background-color: $c-white;
        position: relative;
        z-index: 9 !important;

        .modal-header {
            text-align: end;

            .action-close {
                all: unset;
                cursor: pointer;

                span {
                    color: $c-grey-1;
                    font-size: 1.6rem;
                    border-bottom: 1px solid $c-grey-1;
                }
            }
        }
    }

    .modals-overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.75) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.modals-wrapper {
    display: none;
}


// =============================================
// Social Housing modal
// =============================================
.modal-socialHousing { display: none;}

.modal-socialHousing-container {
    width: 90vw;
    max-width: 1000px;
    padding: 20px;
    border-radius: 15px;

    .modal-socialHousing {
        display: flex;
        gap: 50px;

        &__img {
            display: none;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            text-align: center;

            >div:has(img),
            .btn {
                margin: 30px 0;
            }

            h5 {
                max-width: 375px;
                font-family: $roboto-m;
                font-size: 3.6rem;
                color: $c-main;
                line-height: 4.2rem;
            }

            p {
                font-size: 1.6rem;
                line-height: 2.6rem;
                color: $c-grey-2;
            }
        }

    }
}

//
// Responsive
//
/* ----------------- Tablette 800px
*/
@include breakpoint(sm) {
    .modal-socialHousing-container {
        

        .modal-socialHousing {

            &__img {
                display: block;
            }

            &__content {
                text-align: start;
                align-items: start;
                
                >div:has(img),
                .btn {
                    margin: 0 0;
                }
                
                .actions-container {
                    display: flex;
                    gap: 45px;
                    align-items: center;
                }
            }
        }
    }
}