// =============================================
// Customer
// =============================================

// General
.account {
    // Title
    .page-title { }

    // Subtitle
    .sub-title { }

    // Content
    .h3-title {
        border-bottom:  1px solid $c-input-border;
        color:          $c-project2;
        padding:        10px 0;
        text-transform: uppercase;
    }
    // Columns
    .columns {
        font-family: "Montserrat", sans-serif;
        margin:     60px auto;
        max-width:  $largeurSite;
        padding:    0 20px;
    }
    // Sidebar
    .sidebar {
        float:          left;
        padding:        0 10px;
        width:          20%;
        // Navigation
        .block.account-nav {
            background: $c-grey-light;
            padding:    40px 0;
        }
        .content {
            max-width: 165px;
            margin: 0 auto;
            .nav { padding: 0; }
            .nav.item {
                color:  $c-project2;
                cursor: pointer;
                margin: 0 0 20px;
                &:hover:after {
                }
                &.current {
                    &:after {
                    }
                    strong { font-weight:normal; }
                }
                a { color:  $c-project2; }
            }
        }
    }
    // Main Column
    .column.main {
        float:          left;
        padding:        0 10px;
        width:          80%;
    }
    //Edit link
    .action.edit {
        border-bottom: 1px solid transparent;
        color: $c-project5;
        @include transition(all 0.3s ease-in);
        &:hover {
            border-bottom: 1px solid $c-project5;
        }
    }
    // Name prefix
    .name-prefix {
        font-size:      1.4rem;
        text-transform: uppercase;
        .required {
            margin-right: 20px;
            position:     relative;
            top:          2px;
        }
        .control {
            display: inline-block;

        }
    }
    // Address
    address {
        font-style: normal;
    }
    .wide > div,
    .widget-name {
        margin: 0 0 15px;
        &:last-child { margin: 0; }
    }
    .back-link {
        float: left;
        + .button { float: right; }
    }
}

// Create Account
.customer-account-create,
.block-new-customer {
    .create-account-button {
        margin: 40px 0 0;
    }
    .title-customer-create {
        font-size:  1.8rem;
        margin:     0 0 20px 0;
    }
    form {
        margin: 50px 0;
        ul li { margin: 0; }

    }
    .input-box { margin-bottom: 15px; }
    .buttons-set { margin-top: 50px; }
    // Name prefix
    .name-prefix {
        font-size:      1.4rem;
        text-transform: uppercase;
        .required {
            margin-right: 20px;
            position:     relative;
            top:          2px;
        }
    }
}
// Login page
.customer-account-login { }

// Forget Password
.customer-account-forgotpassword {
    .buttons-set {
        .button { float: right; }
        .back-link { float: left; }
    }
}

// Dashboard
.dashboard,
.my-account-container { }

// Edit Address
.customer-address-form {
    .form-list {
        margin: 0 0 50px;
    }
    .fields {
        .field {
            margin: 0 0 15px 0;
            &:last-child {
                margin: 0;
            }
        }
    }
    .buttons-set .button {
        margin: 20px auto 0;
    }
    .back-link {
        position: absolute;
        top:      0;
        right:    0;
        .button {
            display: inline;
            border:  none;
            &:hover {
                background: none;
                color:      $c-grey;
            }
        }
    }
}
// Wishlist
.wishlist-index-index { }
// Share Wishlist
.wishlist-index-share { }
// Sales Order
.sales-order-view { }
.sales-order-history { }
// Account Edit
.customer-account-edit {
    .form-list {
        li {
            margin: 0 0 30px;
        }
    }

}
// Logout Page
.customer-account-logoutsuccess { }
//
// Responsive
//
@media (min-width: $large) {
    .customer-account {
    }
}
@include respond-to(medium) {
    .account {

    }
}
@include respond-to(small) {
    .account {
        .column.main {
            float:      none;
            padding:    0;
            width:      100%;
        }
        .sidebar {
            float:      none;
            margin:     0 0 50px;
            padding:    0;
            width:      100%;
            .block.account-nav {
                text-align: center;
            }
            .content .nav.item {
                &.current:after {
                    width: 100%;
                }
                &:hover:after {
                    width: 100%;
                }
            }
        }
    }
}
@include respond-to(600px) {

}