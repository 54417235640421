// =============================================
// Button
// =============================================

// Base button
.btn {
    position:               relative;
    -webkit-appearance:     none;
    display:                inline-block;
    align-items:            center;
    padding:                20px 30px;
    cursor:                 pointer;   
    border-radius:          200px;
    text-align:             center;

    .btn-label {
        display:                block;
        color:                  $c-white;
        font-family:            $roboto-m;
        font-size:              1.8rem;
        font-weight:            500;
    }
}

// Varient button
.btn {

    &:hover {
        background-color: $c-grey-1;
    }

    //Red
    &__main {
        background-color:       $c-main;
    }

    // Red dark
    &__main-dark {
        background-color:       $c-main-darker;
    }

    //White
    &__white {
        background-color:       $c-white;
        border:                 1px solid $c-grey-5;

        .btn-label {
            color:                  $c-grey-2;
        }
        
    }

    // No btn style
    &__noStyle {
        all: unset;
        cursor: pointer;
        display: inline-block;

        &:hover {
            background-color: transparent;
        }

        .btn-label {
            color: $c-grey-3;
            font-size: 1.6rem;
            border-bottom: 1px solid $c-grey-3;
        }
    }

    //Blue
    &__blue {
        background-color:       $c-grey-2;
    }

    //Green
    &__green {
        background-color:       $c-success;
    }

    //Account
    &__account {
        padding-right: 55px;
        
        &::after{
            content: '';
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translatey(-50%);
            width: 25px;
            height: 25px;
            background: url('../images/icons/uEA17-account.svg') center no-repeat;
        }

        &:hover {
            background-color: $c-grey-2;

            span {
               color: $c-white; 
            }

            &::after{
                background: url('../images/icons/uEA18-account-white.svg') center no-repeat;
            }
        }
    }
}


// Base button
.button {
    -webkit-appearance: none;
    background:         $c-button;
    border:             2px solid $c-button;
    color:              $c-white;
    cursor:             pointer;
    display:            inline-block;
    font-size:          1.1rem;
    font-weight:        500;
    height:             40px;
    line-height:        36px;
    padding:            0 15px;
    position:           relative;
    text-align:         center;
    text-transform:     uppercase;
    vertical-align:     middle;
    width:              auto;
    font-family: $montserrat;
    @include border-radius(50px);
    @include transition(all 250ms linear);
    &:hover {
        background-color: transparent;
        color:            $c-button;
    }
    &.add {
        width: 130px;
        background: $c-black;
        margin: 0 auto;
        border: none;
        @include border-radius(40px);
    }
    &.white {
        background: $c-white;
        border: 2px solid $c-white;
        color: $c-project2;
        &:hover {
            background: transparent;
            color: $c-white;
        }
    }
    &.blue {
        background: $c-project9;
        border: 2px solid $c-project9;
        &:hover {
            background: transparent;
            color: $c-project9;
        }
    }
    &.orange {
        background: $c-project10;
        border: 2px solid $c-project10;
        &:hover {
            background: transparent;
            color: $c-project10;
        }
    }
    &.green {
        background: $c-project11;
        border: 2px solid $c-project11;
        &:hover {
            background: transparent;
            color: $c-project11;
        }
    }
    &.yellow {
             background: $c-project14;
             border: 2px solid $c-project14;
             &:hover {
                 background: transparent;
                 color: $c-project14;
             }
    }
    &.bt-border{
        background: none;
        @include border-radius(50px);
        border: 2px solid $c-button ;
        color:  $c-button;
        &:hover{
            border-color: $c-project1;
        }
    }
    &.button-grey{
        height: 45px;
        line-height: 40px;
        text-transform: uppercase;
        font-size: 1.2rem;
        background: $c-project2;
        border: 2px solid $c-project2;
        @include border-radius(5px);
        &:hover{
            border-color: $c-project10;
            background: $c-project10;
            color: $c-white;
        }
    }
    // Small
    &.small {
        display: inline-block;
        width:   auto;
    }
    // Icon button
    .icon {
        font-size: 20px;
        margin:    0 10px 0 0;
    }
    // Loading button
    &.loading {
        @include text-hidden();
        > span { }
        &:before { }
        &:hover { }
    }
    // Cart
    &.btn-cart {
        > span { }
        &:before { }
        &:hover { }
    }

    // Template Button
    &.video {
        padding: 6px 20px 6px 50px;
        text-align: left;
        .button-content {
            padding: 0 0 0 30px;
        }
        &:before {
            content:"\EA0D";
            font-family: "iconfont";
            font-size:  1.8rem;
            padding: 0 10px 0 0;
            position: absolute;
            left: 20px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
    &.order {
        padding: 6px 20px 6px 65px;
        text-align: left;
        .button-content {
            padding: 0 0 0 50px;
        }
        &:before {
            border-right: 2px solid;
            content:"\EA09";
            font-family: "iconfont";
            font-size:  1.8rem;
            padding: 0 10px 0 0;
            position: absolute;
            left: 25px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
    &.appointment {
        padding: 6px 20px 6px 65px;
        text-align: left;
        .button-content {
            display: block;
            padding: 0 0 0 50px;
        }
        &:before {
            border-right: 2px solid;
            content:"\EA09";
            font-family: "iconfont";
            font-size:  1.8rem;
            padding: 0 10px 0 0;
            position: absolute;
            left: 25px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
    &.picto-orange {
        &:before {
            @include transition(all 250ms linear);
            color: $c-project10;
        }
        &:hover:before {
            color: $c-white;
        }
    }
    &.picto-green {
        &:before {
            @include transition(all 250ms linear);
            color: $c-project11;
        }
        &:hover:before {
            color: $c-white;
        }
    }
    &.picto-blue {
        &:before {
            @include transition(all 250ms linear);
            color: $c-project9;
        }
        &:hover:before {
            color: $c-white;
        }
    }
    &.picto-yellow {
        &:before {
            @include transition(all 250ms linear);
            color: $c-project14;
        }
        &:hover:before {
            color: $c-white;
        }
    }
}
// Animation rotating
@keyframes rotating {
    from {
        @include transform(rotate(0deg));
    }
    to {
        @include transform(rotate(360deg));
    }
}
// Remove Button
.btn-remove {
}