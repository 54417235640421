// =============================================
// List of Popin
// =============================================
// General


//
// Popin Close Button
//
.fancybox-wrap{
    .fancybox-skin{
        background-color: $c-white;
        .fancybox-close{
            color: lighten($c-project2, 50%);
            width: 40px;
            height: 40px;
            background: #fff;
            line-height: 35px;
            text-align: center;
            top: 5px;
            right: 5px;
            @include border-radius(100%);
            &:before{
                content:"+";
                display: block;
                font-size: 3rem;
                position: relative;
                top: 3px;
                text-transform: uppercase;
                @include transform(rotate(45deg));
            }
            &:hover {
                color: $c-project2;
            }
        }
    }
}

//
// Responsive
//
@include respond-to(medium) {

}
@include respond-to(small) {

}
