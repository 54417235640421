// =============================================
// HomePage
// =============================================

.cms-prx-homepage #maincontent {
    h4 {
        font-family: $roboto-m;
        font-size: 30px;
        color: $c-grey-1;
        font-weight: 500;
    }

    h5 {
        font-family: $roboto-m;
    }

    .btn {
        margin: 30px auto;
    }

    p {
        color: $c-grey-2;
        font-size: 16px;
    }
}

.aidStrate-container,
.reinsurance-container,
.tipsandtrick-container,
.push-marketing-container,
.certifications-container {
    margin: 100px auto;
}


//socialHousing
.socialHousing-container {
    display: none;
    position: relative;
    margin-top: -20px;
    padding: 0 20px;
    z-index: 2;

    .socialHousing-banner {
        display: flex;
        align-items: center;
        padding: 5px 50px;
        border-radius: 105px;
        background-color: $c-white;
        box-shadow: 0px 25px 40px rgba(12, 35, 44, 0.1);
    }

    .socialHousing-banner>div:nth-child(1) {
        font-size: 2.4rem;
        color: $c-main;
        min-width: 230px;

        h5 {
            margin: 0 20px;
            line-height: 2.8rem;
        }
    }

    .socialHousing-banner>p {
        line-height: 2.6rem;
    }

    .socialHousing-banner>div:nth-child(3) {
        min-width: 200px;
        margin-left: 10%;
    }
}

// Situation
.situation-container {
    margin: 75px auto;
    padding: 0 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    color: $c-white;

    .situation-card {
        position: relative;
        border-radius: 15px;
        overflow: hidden;
        height: 80vw;
        width: 80vw;

        
        @media (max-width: $large) {
            br {
                content: "";
            }
        }

        @media (max-width: $small) {
            width: 100%;
        }

        img {
            width: 100%;
        }

        .overlay-card {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.75) 100%);
            transition: opacity .3s linear;
        }

        .situation-content {
            position: absolute;
            z-index: 2;
            left: 30px;
            right: 30px;
            bottom: 0;
            transition: transform .3s linear;

            @media (min-width: $small) {bottom: 40px;}

            h5 {
                font-size: 2.4rem;
            }

            .desc {
                margin-top: 10px;
                line-height: 2.4rem;

            }

            h5,
            .desc {
                transition: opacity .3s ease-in;
            }

            
            .btn { 
                padding: 20px; 
                
                @media (max-width: $small) {
                    min-width: 230px;
                }
            }
            

            .btn-label {
                font-size: 1.6rem;
            }
        }

        &:hover {
            .situation-content {
                transform: translateY(-15%);
            }

            .overlay-card {
                opacity: 1;
            }

            h5,
            .desc {
                opacity: 0;
            }
        }
    }
}

//Mon Chauffagiste Privé
.mcp-container {
    position: relative;

    .mcp-content {
        background-color: $c-white;
        width: 80vw;
        margin: 0 auto;

        .mcp-infos {

            .mcp-logo svg {
                max-width: 100%;

                //LOGO ANIMATION
                #svgMcp-mcp_blue {
                    @include drawSvg(1700, true)
                }
    
                #svgMcp-mcp_red {
                    @include drawSvg(520, true)
                }
    
                #svgMcp-by {
                    @include drawSvg(240, true)
                }

                #svgMcp-wheel {
                    opacity: 0;
                    transition: opacity .2s ease-out;
                }
    
                #svgMcp-proxiserve {
                    path {
                        opacity: 0;
                        transform: translateX(-5%);
                        
                    }
                }
            }

            &.animated {
                .mcp-logo svg {
                    //Draw
                    #svgMcp-mcp_blue,
                    #svgMcp-mcp_red,
                    #svgMcp-by {
                        animation: 10s forwards draw, 2s 1.5s forwards fill-in;
                    }

                    //Wheel
                    #svgMcp-wheel {
                        opacity: 1;
                        transform-origin: 21% 75%;
                        animation: roll 1.5s 2;
                    }

                    // Reveal
                    #svgMcp-proxiserve {
                        @for $i from 1 through 10 {
                            $timeMultiplyer: calc(#{$i}/4);
                
                            path:nth-child(#{$i}) {
                                opacity: 1;
                                transform: translateX(0);
                                transition: all .2s calc(0.5s * #{$timeMultiplyer}) ease-in-out;
                            }
                            
                        } 
                    }
                }
            }

            h4 {
                margin-top: 40px;
                margin-bottom: 15px;
            }

            p {
                line-height: 2.6rem;
            }

            .btn {
                width: 100%;
            }   
        }

        .mcp-categories {
            display: flex;
            flex-wrap: wrap;
            @media (max-width: $small) {
                gap: 5px;
            }
            


            .category-card {
                padding: 10px;
                width: 50%;
                word-break: break-word;

                @media (max-width: $small) {
                    width: calc(50% - 5px);
                }

                img {
                    position: relative;
                    z-index: 2;
                    margin-right: 20px;
                    max-width: 150px;
                    max-height: 150px;
                    border-radius: 10px;

                    @media (max-width: $small) {width: 100%;}
                }

                h5 {
                    position: relative;
                    z-index: 1;
                    background-color: $c-white;
                    font-size: 2rem;
                    color: $c-grey-1;
                    margin-bottom: 10px;
                }

                a.link-icon {
                    color: $c-grey-3;

                    &:hover {
                        color: $c-main;
                    }
                }
            }
        }

    }
}

//Easy entretien banner
.eeBanner-container {
    padding: 100px 0;

    .eeBanner-content {
        border-radius: 15px;
        background-color: #EF604E;
        text-align: center;


        .eeBanner-content {

            &__image {
                position: relative;

                img {
                    transform: translate(0, -20%);
                }
            }

            &__image,
            &__title,
            &__right {
                flex: 1;
                color: $c-white;
            }

            &__title {
                padding: 40px 0;

                h4 {
                    margin-bottom: 25px;
                    color: $c-white !important;
                }
            }

            &__right {
                flex: 2;
            }

            &__text {
                padding: 30px 40px 0;
                position: relative;
                font-size: 2.4rem;
                line-height: 3.2rem;
                margin-top: 30px;
                

                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 40px;
                    height: 1px;
                    width: calc(100% - 80px);
                    background: $c-white;
                }

                span {
                    font-size: 4rem;
                }
            }
        }
    }
}

//Aids strate
.aidStrate-container {
    width: 80vw;
    margin: 0 auto;

    &__cta-content {

        h4 {
            span:first-child {
                color: $c-main;
            }
        }

        .btn {
            margin-left: 0 !important;


        }
    }

    &__image-content {
        text-align: center;

        img {
            border-radius: 15px;
        }
    }

    &__aids-content {
        margin: 30px 0;
        font-size: 1.8rem;

        .aid-infos {
            &>div:nth-child(1) {
                color: $c-grey-3;
            }

            &>div:nth-child(2) {
                margin: 5px 0;
                font-size: 3.6rem;
                color: $c-success;
            }

            &>div:nth-child(3) {
                font-family: $roboto-b;
                color: $c-grey-1;
            }
        }
    }
}

// Reinsurance
.reinsurance-container {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    &__item {
        width: calc(50% - 10px);

        .item-number {
            font-size: 4rem;
            color: $c-main;
            font-weight: 300;
        }

        .item-text {
            font-size: 1.4rem;
            font-family: $roboto-m;
            color: $c-grey-1;
        }
    }

}

// Tips and Tricks
.tipsandtrick-container {
    width: 80vw;
    margin: 100px auto;

    &__articles-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .article {
            border-radius: 15px;
            width: 35vw;
            height: 35vw;

            // Find another issue
            img.article__picture {
                max-width: none;
                height: 100%;
                transform: scale(1) translate(-25%, 0%);
            }

            //Fill the card to handle link click
            &__content {
                top: 0;
                left: 0;
                padding: 75% 20px 0;
                transform: translateY(0);

                * {
                    color: $c-white;
                }

                .card-title {
                    margin-bottom: 10px;
                    font-size: 1.4rem;
                    font-family: $roboto-b;
                }
            }
        }

    }
}

// Certifications
.certifications-container {
    width: 80vw;
    margin: 100px auto;

    h5 {
        font-weight: 500;
        font-size: 2.4rem;
        margin-right: 100px;
    }

    .logos-container {
        display: flex;
        align-items: center;
        gap: 45px;
        flex-wrap: wrap;
        margin-top: 24px;
    }

}

// Portrait
.profiles-container {
    font-family: $montserrat;
    max-width: $largeurSite;
    margin: 0 auto 100px;
    padding: 0 $paddingContent;

    .presentation {
        color: $c-project7;
        float: left;
        min-height: 330px;
        width: 30%;
    }

    .profile-content {
        float: left;
        width: 70%;
    }

    .profile-picture {
        float: left;
        width: 60%;
        min-height: 1px;

        img {
            position: absolute;
            max-width: 535px;
            width: 40%;
        }
    }

    .profile-info {
        padding: 0 0 0 40px;
        float: left;
        width: 40%;
    }

    .profile-desc {
        margin: 0 0 20px;

        &:after {
            content: "";
            background-color: $c-project4;
            display: block;
            width: 100px;
            height: 1px;
        }

        .name {
            font-family: $montserrat;
            font-size: 2.4rem;
            margin: 0 0 10px;
        }

        .job {
            text-transform: uppercase;
            color: $c-project1;
            font-family: $open;
            font-size: 1.4rem;
            font-weight: 600;
        }

        .location {
            text-transform: capitalize;
        }

        .desc {
            color: $c-project4;
            margin: 15px 0 30px;
        }
    }

    .profile-certified {

        .label,
        .list {
            display: inline-block;
            vertical-align: middle;
        }

        .label {
            text-transform: uppercase;
            font-size: 1.2rem;
            margin: 0 20px 0 0;
        }

        ul li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    .leading {
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    .h1-title {
        color: $c-project2;
        line-height: 34px;
    }

    .list-job {
        margin: 40px 0;

        li {
            color: $c-black;
            cursor: pointer;
            display: inline-block;
            margin: 0 20px 0 0;
            padding: 5px 0;

            &:last-child {
                margin: 0;
            }

            &:before {
                background-color: $c-project4;
                content: "";
                display: inline-block;
                margin: 0 10px 0 0;
                width: 8px;
                height: 8px;
                @include border-radius(100%);
                @include transition (all .3s linear);
            }

            &.active:before {
                background-color: $c-project1;
            }
        }

        &.profile1 li:first-child:before {
            background-color: $c-project1;
        }

        &.profile2 li:nth-child(2):before {
            background-color: $c-project1;
        }

        &.profile3 li:last-child:before {
            background-color: $c-project1;
        }
    }
}

.button {
    &.white {
        color: #212121;

        &:hover {
            background: transparent;
            color: #ffffff;
        }
    }
}

.profiles-bg {
    background-color: $c-project5;
    padding: 60px 40px 50px;
}

// Marketing
.push-marketing-container {
    width: 90vw;
    margin: 30px auto;

    .marketing1,
    .marketing2 {
        border-radius: 15px;
        max-width: 640px;
        margin: 30px auto;
    }

    .marketing1 {
        padding: 50px 40px;
        border: 1px solid $c-grey-5;


        &__title span {
            color: $c-main;
        }

        &__condition {
            margin-top: 10px;
        }

        &__stores {
            margin: 30px 0;
            display: flex;
            gap: 15px;
            height: 22px;
        }

        &__infos-card {
            position: relative;
            padding: 35px 30px 25px;
            background-color: $c-grey-6;
            border-radius: 10px;
            margin: 40px 0;

            .gift-card {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
            }

            .desc {
                margin-left: 20px;

                &>div:first-child {
                    font-family: $roboto-m;
                    font-size: 2.4rem;
                    color: $c-main;
                }

                &>div:nth-child(2) {
                    margin-top: 5px;
                    font-size: 1.6rem;
                    color: $c-grey-1;
                }
            }
        }

        &__btn {
            margin: 10px 0 !important;
        }
    }

    .marketing2 {

        img.card-background {
            height: 100%;
            max-width: none;
            min-height: 440px;
            transform: translateX(-25%);
        }

        .card-content {
            color: $c-white;
            left: 30px;

            div:first-child {
                font-size: 5rem;
            }

            div:nth-child(2) {
                padding: 10px 0;
                max-width: 350px;
                font-size: 2.4rem;
            }
        }
    }
}



//
// Responsive
//

/* ----------------- Animations Mobile
*/
@include breakpoint(only-ms){
    // Situation
    .situation-container {
        .animate-target {
            opacity: 0;
            transform: translate(0, 25%);
            transition: all .5s ease-out;

            // Animated state
            &.animated {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }

    //Mon Chauffagiste Privé
    .mcp-container {
        .animate-target {
           img {
                transform: translate(0, 100px);
                transition: transform .4s linear;
            }

            .category-content {
                div:has(a) {
                    opacity: 0;
                    transform: translate(0, -25px);
                    transition: all .3s .35s linear;
                }
            }

            // Animated state
            &.animated {
                img,
                .category-content div:has(a) {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }
        
    }

    // Tips and Tricks
    .tipsandtrick-container {
        &__articles-container{
            
            // before Animated state
            .article {
                opacity: 0;
                transition: all .3s ease-out;
            }

            .article:nth-child(odd){
                transform: translateX(-25%);
            }

            .article:nth-child(even){
                transform: translateX(25%);
            }
            

            // Animated state
            .article:nth-child(odd),
            .article:nth-child(even) {
                &.animated {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
             
        }
    }
}


/* ----------------- Intermediaire 640px
*/
@include breakpoint(ms) {

    // Situation
    .situation-container {
        .situation-card {
            width: fit-content;
            height: fit-content;
        }
    }

    //Mon Chauffagiste Privé
    .mcp-container {
        .mcp-content {
            .mcp-infos .btn {
                width: auto;
            }
        }
    }

    //Easy entretien banner
    .eeBanner-container {
        .eeBanner-content {
            width: 75vw;
            padding: 0 90px;
        }
    }

    // Reinsurance
    .reinsurance-container {
        &__item {
            text-align: center;

            .item-number {
                font-size: 7rem;
            }

            .item-text {
                font-size: 1.8rem;
            }
        }
    }

    // Tips and Tricks
    .tipsandtrick-container {
        text-align: center;

        &__articles-container {
            .article {
                height: auto;

                // Find another issue
                img.article__picture {
                    max-width: 100%;
                    height: auto;
                    transform: initial;
                }

                &__content {
                    padding: 15% 20px 0;
                    text-align: start;

                    .card-title {
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }

    // Marketing
    .push-marketing-container {
        .marketing2 {
            img.card-background {
                transform: translateX(0);
            }
        }
    }
}

/* ----------------- Tablette 800px
*/
@include breakpoint(sm) {

    //socialHousing
    .socialHousing-container {
        display: block;
    }

    // Situation
    .situation-container {}

    //Mon Chauffagiste Privé
    .mcp-container {

        #background-grey {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1000px;
            background: linear-gradient(180deg, #F5F5F7 0%, #FFFFFF 100%);
            z-index: -1;
        }

        .mcp-content {
            width: 90vw;
            display: flex;
            gap: 8%;
            align-items: center;
            padding: 50px 100px;
            border-radius: 15px;
            box-shadow: 0px 25px 40px rgba(12, 35, 44, 0.1);

            .mcp-infos {
                width: 35%;


            }

            .mcp-categories {
                width: 60%;
            }
        }
    }

    //Easy entretien banner
    .eeBanner-container {
        padding: 100px 20px;

        .eeBanner-content {
            width: auto;
            display: flex;
            gap: 50px;
            align-items: center;
            text-align: start;
            padding: 0 10px;

            .eeBanner-content {
                &__image {
                    position: relative;
                    align-self: flex-end;

                    img {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }

                &__right {
                    align-items: center;
                }

                &__text {
                    margin-top: 0px;

                    &:before  {
                        display: none;
                    }
                }

                &__cta {
                    min-width: 250px;

                    .btn {
                        margin-bottom: 0 !important;
                    }
                }
            }


        }
    }

    //Aids strate 
    .aidStrate-container {
        text-align: center;
    }

    // Tips and Tricks
    .tipsandtrick-container {
        &__articles-container {
            .article {
                &__content {
                    padding: 25% 20px 0;
                }
            }
        } 
    }

    // Marketing
    .push-marketing-container {

        .marketing1 {
            padding: 50px 70px;

            &__infos-card {
                display: flex;
                margin: 15px 0;
                padding: 20px 30px;

                .gift-card {
                    position: relative;
                    transform: translateY(0);
                }
            }

            &__stores {
                img {
                    max-height: 36px;
                }
            }
        }

        .marketing2 {
            .card-content {
                left: 70px;
            }
        }
    }

    // Certification
    .certifications-container {
        display: flex;
        align-items: center;

        .logos-container {
            margin-top: 0;
        }
    }
}


/* ----------------- Screen 1024px
*/
@include breakpoint(lg) {

    //socialHousing
    .socialHousing-container {
        .socialHousing-banner>div:nth-child(1) {
            display: flex;
            align-items: center;
            min-width: 350px;
        }
    }

    // Situation
    .situation-container {
        flex-wrap: nowrap;
    }

    //Mon Chauffagiste Privé
    .mcp-container {

        .mcp-content {
            position: relative;
            z-index: 2;
        }

        .category-card {

            img {
                // before Animated state
                opacity: 0;
                transform: translate(100%, 0);
                transition: all .4s linear;
            }

            .category-content {

                h5 {
                    // before Animated state
                    opacity: 0;
                    transform: translate(0, 110%);
                    transition: all .3s .35s linear;
                }

                div:has(a) {
                    // before Animated state
                    opacity: 0;
                    transform: translate(-10%, 0);
                    transition: all .3s .35s linear;
                }
            }

            // Animated state
            &.animated {

                img,
                .category-content>h5,
                .category-content>div:has(a) {
                    transform: translate(0, 0);
                    opacity: 1;
                }

            }

        }
    }

    //Easy entretien banner
    .eeBanner-container {
        position: relative;
        z-index: 1;

        .eeBanner-content {
            // before Animated state
            position: relative;
            opacity: 0;
            transform: translate(0, -100%);
            transition: all .6s ease-out;

            .eeBanner-content {
                &__right {
                    display: flex;
                }
            }

        }

        // Animated state
        &.animated {
            .eeBanner-content {
                transform: translate(0, 0);
                opacity: 1;
            }

        }
    }

    //Aids strate
    .aidStrate-container {
        width: auto;
        padding: 0 20px;
        text-align: start;

        .aidStrate-content {
            display: flex;
            justify-content: space-between;
            gap: 100px;
        }

        &__cta-content {
            flex: 1;
            order: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__image-content {
            flex: 1;
            order: 2;
        }

        &__aids-content {
            margin: 0 0;
            flex: 1;
            order: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        // before Animated state
        &__cta-content {
            :nth-child(1),
            :nth-child(2) { 
                transform: translate(50%, 0);
            }
        }

        &__aids-content {
            :nth-child(1),
            :nth-child(2) { 
                transform: translate(-50%, 0);
            }
        }

        &__cta-content,
        &__aids-content {
            :nth-child(1),
            :nth-child(2) {
                opacity: 0;
                transition: all .3s ease-in;
            }

            :nth-child(2) {
                transition-delay: .15s;
            }
        }

        // Animated state
        &.animated {
            .aidStrate-container__cta-content,
            .aidStrate-container__aids-content {
                :nth-child(1),
                :nth-child(2) {
                    transform: translate(0, 0);
                    opacity: 1;  
                }
            }
        }
    }

    // Reinsurance
    .reinsurance-container {
        width: auto;
        padding: 0 20px;

        &__item {
            width: auto;
            flex: 1;
            gap: 10px;

            .item-number {
                font-weight: 400;
            }

            &:not(:first-child) {
                border-left: 1px solid $c-grey-5;
            }


        }
    }

    // Tips and Tricks
    .tipsandtrick-container {
        display: flex;
        align-items: center;
        width: auto;
        padding: 0 20px;
        gap: 50px;
        text-align: start;

        &__title-container {
            flex: 1;
        }

        &__articles-container {
            flex: 3;

            //Avoid scroll x before animated
            overflow-x: hidden;

            
            .article {
                width: 30%;

                &__content {
                    padding: 15% 20px 0;
                }
                
                // Before animation state
                opacity: 0;
                transform: translate(30%, 0);
                transition: all .3s ease-in;

                &:nth-child(2) {
                    transition-delay: .2s;
                }

                &:nth-child(3) {
                    transition-delay: .4s;
                }

                &:nth-child(4) {
                    transition-delay: .1s;
                }

                
                &:nth-child(5) {
                    transition-delay: .3s;
                }

                &:nth-child(6) {
                    transition-delay: .5s;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            // Animated state
            &.animated {
                .article {
                   opacity: 1;
                    transform: translate(0, 0); 
                } 
            }
        }
    }

    // Marketing
    .push-marketing-container {
        display: flex;
        gap: 20px;

        .marketing1,
        .marketing2 {
            width: 50%;

            opacity: 0;
            transition: 
                opacity .3s ease-in,
                transform .3s .1s linear;
        }

        .marketing1 {
            transform: translate(0, 10%);
        }

        .marketing2 {
            transform: translate(0, -10%);
        }

        // Animated state
        &.animated {
            .marketing1,
            .marketing2 {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }
}

/* ----------------- Screen extra large 1280px
*/
@include breakpoint(xl) {

    //Mon Chauffagiste Privé
    .mcp-container {
        .mcp-content {
            .mcp-categories {
                .category-card {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    // Tips and Tricks
    .tipsandtrick-container {
        &__articles-container {
            .article {
                &__content {
                    padding: 25% 20px 0 30px;
                }
            }
        }
    }
}

//
// Proxiserve Professionnels
//
.page-pro-template {
    font-family: $montserrat;

    .container div.text {
        margin: 0 0 30px;

        &:after {
            display: none;
        }
    }

    .desc {
        color: $c-project4;

        p {
            margin: 0 0 30px;
        }

        ul {
            margin: 30px 0;
        }

        li {
            display: block;
            font-size: 1.3rem;
            padding: 0 0 0 20px;
            position: relative;
            margin: 0 0 10px;
            color: $c-project12;

            &:last-child {
                margin: 0;
            }

            &:before {
                content: "\EA08";
                color: $c-project1;
                display: block;
                font-family: "iconfont";
                font-size: .8rem;
                position: absolute;
                left: 0;
                top: 6px;
                @include border-radius(100%);
            }
        }
    }
}