// Reviews widget
.reviews-widget__container {
    position: relative;
    display: flex;
    align-self: flex-start;
    padding: 12px 15px 12px 0;
    box-shadow: 0px 0px 15px rgba(11, 35, 45, 0.2);
    border-radius: 5px 5px 10px 5px;
    background-color: $c-white;
    max-width: 155px;

    @include breakpoint(lg) {
        margin-left: 20px;
        max-width: none;
    }

    .reviews-widget__logo {
        width: 40px;
        height: 40px;

        img {
            margin-left: -10px;
            transition: transform .2s ease-in;
        }  
    }

    .reviews-widget__rate {
        height: 15px;
        margin-bottom: 5px;

        img {
            max-height: 100%;
        }
    }

    .reviews-widget__content {

        p {
            font-size: 1.2rem;
            margin: 0;
            font-style: italic;
        }

        span {
            color: $c-black;
            font-weight: 700;
        }
    }

    .pleats {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        background: #D7DFE2;
        border-radius: 0px 0px 10px 0px;
    }

    &:hover {
        .reviews-widget__logo {
            img {
                transform: scale(1.1);
            }  
        }

        span {
            color: $c-main;
        }
    }
}
