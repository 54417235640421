// =============================================
// FAQ page
// =============================================


//
// Global
//
.faq-questions-answers {
    @include border-radius(5px);
    background: $c-white;
    border:     1px solid $c-input-border;
    margin:     0 0 15px;
    &.active {
        border: none;
        border-bottom: 1px solid red;
        border-top: 1px solid orange;
        @include border-radius(5px);
        background-size: 1px 100%;
        background-position: 0 0, 100% 0;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
        background-image: -webkit-linear-gradient(orange, red), -webkit-linear-gradient(orange, red);
        background-image: -moz-linear-gradient(orange, red), -moz-linear-gradient(orange, red);
        background-image: -o-linear-gradient(orange, red), -o-linear-gradient(orange, red);
        background-image: linear-gradient(orange, red), linear-gradient(orange, red);
        .faq-question:after { @include transform(scaleY(-1)); }
    }
}
.faq-question {
    color:          $c-project2;
    cursor:         pointer;
    font-family:    $montserrat;
    font-size:      1.6rem;
    padding:        15px 75px 15px 30px;
    position:       relative;
    &:after {
        content:         "\EA06";
        color:          $c-project4;
        font-family:    "iconfont";
        display:         block;
        font-size:      1rem;
        position:        absolute;
        right:           30px;
        top:             50%;
        margin-top:      -3px;
        @include transition(all .3s linear);
    }
}
.faq-answer {
    padding: 0 75px 0 30px;
    p {
        margin: 15px 0 20px;
    }
}

@include respond-to(small) {
    .faq-question {
        padding: 15px 60px 15px 15px;
    }

}