@font-face {
    font-family: 'iconfont';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/iconfont/iconfont.eot');
    src: url('../fonts/iconfont/iconfont.woff2') format('woff2'),
         url('../fonts/iconfont/iconfont.woff') format('woff'),
         url('../fonts/iconfont/iconfont.ttf') format('truetype'),
         url('../fonts/iconfont/iconfont.eot?#iefix') format('embedded-opentype');
}

.icon[class^="icon-"],
.icon[class*=" icon-"] {
    display: inline-block;
    font-family: 'iconfont';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

$icons: (
    coche: '\EA01',
    fioul: '\EA02',
    gaz: '\EA03',
    tel: '\EA04',
    menu: '\EA05',
    arrow: '\EA06',
    envelop: '\EA07',
    check: '\EA08',
    calendar: '\EA09',
    map: '\EA0A',
    search: '\EA0B',
    order: '\EA0C',
    video: '\EA0D',
    btn-menu: '\EA0E',
    list: '\EA0F',
    facebook: '\EA10',
    google-plus: '\EA11',
    pinterest: '\EA12',
    twitter: '\EA13',
    logo-youtube: '\EA14',
    youtube: '\EA15',
    rss: '\EA16',
);

@each $name, $icon in $icons {
    .icon-#{$name}:before {
        content: $icon;
    }
}