// =============================================
// PRESS PAGE
// =============================================


//
// LISTING
//
.contentmanager-contentlist-view {

  .text-before {
    .press-banner {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 600px;
      position: relative;
      z-index: -1;
    }
  }

  .content-item {
    &.press {
      width: 50%;
      min-height: 300px;
      .push-marketing {
        width: 100%;
      }
    }
  }

}

// DETAIL
//
.page-press {
  font-family: $montserrat;
  font-size: 1.2rem;
  text-align: center;
  .page-banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: $c-white;
    font-family: $montserrat;
    height: 420px;
    position: relative;
    z-index: -1;
  }
  .page-content {
    background: $c-white;
    max-width: 900px;
    padding: 35px 45px;
    margin: -85px auto 0;
  }
  .press-item {
    max-width: 670px;
    margin: 0 auto;
  }
  .back-container {
    margin: 0 0 25px;
    padding: 0 0 0 20px;
    text-align: left;
    position: relative;
    .link {
      color: #bfbfbf;
      font-size: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      &:before {
        content: "\EA06";
        font-family: 'iconfont';
        font-size: 0.5rem;
        @include transform(rotate(90deg));
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }
  .title {
    font-size: 2.2rem;
    color: $c-project13;
    text-transform: uppercase;
  }
  .sub-title {
    color: $c-project2;
    font-size: 1.8rem;
    margin: 40px 0;
    span {
      color: $c-project13;
    }
  }
  .desc {
    color: $c-project2;
    font-size: 1.4rem;
    margin: 0 0 50px;
    text-align: left;
    span,
    a {

    }
  }
}

@include respond-to(small) {
  .contentmanager-contentlist-view {
    .press-banner {
      background-size: cover;
      background-position: center;
      height: 300px;
      position: relative;
      z-index: -1;
    }
  }

  .page-press {
    .page-banner {
      background-position: 45% center;
      height: 400px;
    }
  }

}
