// =============================================
// Tod Ui-kit
// =============================================

// =============================================
// Wrapper-classes
// =============================================
.wrapper-large {
    max-width: 1400px;
    margin: 0 auto;
}

.wrapper-medium {
    max-width: 1300px;
    margin: 0 auto;
}

.wrapper-small {
    max-width: 1000px;
    margin: 0 auto;
}

// =============================================
// Display-classes
// =============================================
// ----------------------- Display only
// 480px
.mobile_screen-only {
    display: initial;

    @media (min-width: $mq-extra-small)  {
        display: none;
    }
}

// 800px
.tablet_screen-only {
    display: initial;

    @media (min-width: $small)  {
        display: none !important;
    }
}

// 1024px
.medium_screen-only {
    display: initial;

    &.btn {
        display: inline-block;
    }

    @media (min-width: $mq-large)  {
        &.btn {
            display: none;
        }
        display: none;
    }
}

// 1200px
.large_screen-only {
    display: none;

    @media (min-width: $large)  {
        display: initial;
    }
}

// ----------------------- Display start
// 480px
.mobile_screen-start {
    display: none;

    @media (min-width: $mq-extra-small)  {
        display: initial;
    }
}

// 800px
.tablet_screen-start {
    display: none;

    @media (min-width: $small)  {
        display: initial;
    }
}

// 1024px
.medium_screen-start {
    display: none;

    @media (min-width: $mq-large)  {
        display: initial;

        &.btn {
            display: inline-block;
        }
    }
}


// =============================================
// Overlay-classes
// =============================================
.image-overlay {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
}

// =============================================
// Link with arrow icon
// =============================================
.link-icon {
     position: relative;
     padding-right: 20px;

     &::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background: url(../images/right_arrow.svg) center no-repeat;
        transition: all .2s ease-in;
    }

    &:hover {
        &::after {
            transform: translate(-5px, -50%);
        }
    }
}

// =============================================
// Card-container-classes
// =============================================
.card-container {
    position: relative;
    overflow: hidden;

    //Scale img
    .card-link {
        span:not(.inline) {
            display: block;
        }

        img {
            transform: scale(1);
            transition: transform .2s ease-in;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }

            //Handle .link-icon hover effect
            .link-icon::after {
                transform: translate(-5px, -50%); 
            }
        }
    }

    .card-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

// --------------------------- Card overlayed

.card-overlay {
    position: relative;

    .card-content {
        z-index: 2;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.75) 100%);
    }
}


// =============================================
// Mixins
// =============================================
@mixin drawSvg ($stroke-dash:1000, $fill-opacity: false)
{
    stroke-dashoffset: $stroke-dash;
    stroke-dasharray: $stroke-dash;

    @if $fill-opacity {
        fill-opacity: 0;
    }
}