// =============================================
// Font Face
// Example
//@include font-face("myfont", "../fonts/myfont-folder/myfont-regular");
//@include font-face("myfont", "../fonts/myfont-folder/myfont-bold", bold);
//@include font-face("myfont", "../fonts/myfont-folder/myfont-italic", normal, italic);
// =============================================

@include font-face("source-sans-pro", "../fonts/source-sans-pro/source-sans-pro-regular");
@include font-face("source-sans-pro", "../fonts/source-sans-pro/source-sans-pro-bold", bold);
@include font-face("source-sans-pro", "../fonts/source-sans-pro/source-sans-pro-italic", normal, italic);

@include font-face("Roboto", "../fonts/roboto/Roboto-Regular");
@include font-face("Roboto-Medium", "../fonts/roboto/Roboto-Medium");
@include font-face("Roboto-Bold", "../fonts/roboto/Roboto-Bold");
@include font-face("Outfit", "../fonts/outfit/Outfit");