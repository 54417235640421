// =============================================
// Settings
// =============================================
//
// Classic fonts
//
$slick:             slick, Tahoma, Verdana, sans-serif;
$Arial:             Arial, "Helvetica Neue", Helvetica, serif;
$Helvetica:         "Helvetica Neue", Helvetica, Arial, sans-serif;
$Tahoma:            Tahoma, Verdana, Segoe, sans-serif;
$TimesNewRoman:     "Times New Roman", Times, Baskerville, Georgia, serif;
$Verdana:           Verdana, Geneva, sans-serif;
//
// Project fonts
//
$montserrat: 'Montserrat', sans-serif;
$open: 'Open Sans', sans-serif;
$roboto: 'Roboto', sans-serif;
$roboto-m: "Roboto-Medium", sans-serif;
$roboto-b: 'Roboto-Bold', sans-serif;
$outfit: 'Outfit', sans-serif;
$base-font: $roboto;
//
// Base Color
//
$c-black:       #000000;
$c-grey-dark:    #626262;
$c-grey:        #424242;
$c-grey-light:  #f9f9f9;
$c-warning:     #ff0000;
$c-transparent: transparent;

//Tod Color
$c-white:       #ffffff;
$c-main:            #F54E59;
$c-main-darker:     #411827;
$c-main-ligther:    #FFBBBF;
$c-accent:          #B4E100;
$c-accent-darker:   #005E31;
$c-accent-ligther:  #E0F5B1;
$c-grey-1:          #0C232C;
$c-grey-2:          #224B61;
$c-grey-3:          #667F8D;
$c-grey-4:          #A8BAC1;
$c-grey-5:          #D7DFE2;
$c-grey-6:          #F5F5F7;
$c-success:         #72C070;
$c-warning:         #EDCA4E;
$c-error:           #E05A62;
$c-info:            #5694F2;
//
// Header
//
$height-panel: 42px;
$c-bg-panel: #f7f7f7;

$c-bg-header: #f8f8f8;
$height-header: 200px;
//
// Footer
//
$height-footer: 200px;
$c-bg-footer: #c1c1c1;

//
// Project Colors
//
$c-project1: #ff0033;
$c-project2: #212121;
$c-project3: #505050;
$c-project4: #8c8c8c;
$c-project5: #f8f8f8;
$c-project6: #f3f3f3;
$c-project7: #999999;
$c-project8: #a5a5a5;
$c-project9: #53a9ed;
$c-project10: #ef604e;
$c-project11: #66cc9b;
$c-project12: #373737;
$c-project13: #E5002E;
$c-project14: #FFBA00;
$c-project15: #e8e6e6;


//
// Messages Colors
//
$c-error: #ef604e;
$c-success: #66cc9b;
//
// Base Links Colors
//
$c-link: #373737;
$c-link-hover: #ff0033; //#555555;
//
// Base Button Colors
//
$c-button: $c-project1;
$c-little-btn: #e66755;
//
// Form colors
//
$c-label: #8c8c8c;
$c-input-text: #000;
$c-input-border: #8c8c8c;
$c-bg-input: #ffffff;
$c-error-border: #f50057;
$c-input-placeholder: tint($c-project3, 30%);
$c-select-border : #d8d8d8;

//Radius
$radius-md: 15px;

//
// Responsive
//
$largeurSite: 1440px;
$paddingContent: 10px;
$columns: 12;
$gutter: 20px;
$small: 800px;
$medium: 1100px;
$large: 1200px;
$x-large: 1345px;

// -------------
// Media queries Tod
// -------------

// Dimensions
$mq-start-tablet: 700px;
$mq-start-desktop: 1100px;
$mq-layout-width: 1200px;
$mq-start-large-desktop: 1245px;
$mq-container-break: 1620px;

/* Responsive
-------------------------- */
$mq-extra-small:  480px;
$mq-small:        640px;
$mq-medium:       768px;
$mq-large:        1024px;
$mq-extra-large:  1280px;

//
// Height
//
$height-input: 40px;
$height-text-area: 100px;
//
// Width
//
$w-content: auto;
$w-full: 100%;
$w-half: $w-full / 2;
$w-third: $w-full / 3;
$w-two-third: ($w-full / 3) * 2;
$w-fourth: $w-full / 4;
