// =============================================
// Header
// =============================================

//
//Header
//
header .prx-banner {
    
    //
    // Banner
    .banner-content {
        position: relative;

        .logo-mobile {
            div {
                margin: 70px 0;   
            }
        }

        .image-content {
            position: relative;
            border-radius: $radius-md;
            overflow: hidden;
            
            img {
                width: 100%; 
             }

            //img overlay
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 43.23%);
            }
        }
        
        .title-content {
            padding: 0 40px;

            h1 {
                color: $c-grey-3;
                text-transform: uppercase;
                font-size: 1.4rem;
                font-family: $outfit;
                letter-spacing: .15rem;
            }

            h2 {
                margin: 20px 0;
                font-family: $roboto-m;
                font-size: 4rem;

                span {
                    color: $c-main;
                }
            }

            p {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }
    }
}

//
// Responsive
//

//Tablette
@include breakpoint(sm) {
    .header .prx-banner {
        .banner-content {

            .image-content {

                //img overlay
                &::after {
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 100%);
                }
            }

            .title-content {
                position: absolute;
                z-index: 3;
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                max-width: 640px;
                color: $c-white;
                
                h1 {
                    color: $c-white;
                }
            }
        }
    }
}