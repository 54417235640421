// =============================================
// Custom Input Checkbox / Radio button
// =============================================
$imageRadio: '../images/radio.jpg';
$imageCheckbox: '../images/checkbox.jpg';
$widthImage:    20px;
$heightImage:   40px;
.field.choice {
    margin:   0 20px 10px 0;
    position: relative;
    overflow: hidden;
    display:  inline-block;
    label {
        cursor:   pointer;
        position: relative;
    }
    //****** Custom Input Checkbox ****/
    input[type="checkbox"] {
        opacity: 0;
        filter:     progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        display:    inline-block;
        position:   absolute;
        top:        0;
        left:       0;
        margin:     0;
        padding:    0;
        & + label {
            display:    block;
            min-height: ($heightImage / 2);
            line-height: ($heightImage / 2);
            padding:    0 0 0 30px;
            width:      auto;
            &:before {
                cursor:     pointer;
                content:    " ";
                display:    inline-block;
                border:     2px solid $c-project3;
                width:      $widthImage;
                height:     ($heightImage / 2);
                background: url(#{$imageCheckbox}) 0 0 no-repeat;
                margin:     0;
                position:   absolute;
                left:       0;
                top:        0;
                @include border-radius(2px);
                @include transition(border-color 0.3s linear);
            }
            &:hover:before {
                border:     2px solid $c-project5;
            }
        }
        &:checked + label:before,
        &:disabled + label:before {
            background-position: 0 100%;
            border:     2px solid $c-project5;
        }
    }
    //****** Custom Input Radio ****/
    input[type="radio"] {
        opacity: 0;
        filter:     progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        display:    inline-block;
        float:      left;
        position:   absolute;
        top:        0;
        left:       0;
        margin:     0;
        padding:    0;
        & + label {
            float:        left;
            display:      block;
            padding-left: 31px;
            min-height:   (image-height($imageRadio) / 2);
            &:before {
                cursor:     pointer;
                content:    " ";
                display:    inline-block;
                width:      image-width($imageRadio);
                height:     (image-height($imageRadio) / 2);
                background: url(#{$imageRadio}) 0 0 no-repeat;
                margin:     0;
                position:   absolute;
                left:       0;
                top:        2px;
            }
        }
        &:checked + label:before { background-position: 0 100%; }
    }
}
