// =============================================
// Pages CMS
// =============================================

.cms-page-view {
    .columns {
        margin:     50px auto;
        padding:    0 10px;
    }
    .main {
        div {
            font-size:      1.2rem;
            line-height:    20px;
        }
        .link {
            color: $c-link;
            &:hover { color: $c-link-hover; }
        }
        // List
        ul,
        ol {
            margin:         0 0 15px;
            padding-left:   30px;
            li {
                font-size:      1.2rem;
                line-height:    20px;
            }
        }
        // Tableau
        table {
            margin: 0 0 15px;
            width:  100%;
            border: 1px solid $c-black;
            thead {
                th {
                    font-size:     1.4rem;
                    padding:       10px 15px;
                    border-bottom: 1px solid $c-black;
                }
            }
            tbody {
                td { padding: 10px 15px; }
            }
        }
        // Button
        .button {
            display: inline-block;
            padding: 0 15px;
            width:   auto;
        }
    }
}

.cms-mentions-legales,
.cms-mentions-legales {
    .page-title-wrapper {
        margin: 50px 0;
    }
    .main {
        max-width:  870px;
        margin:     50px auto;
    }
}

.cms-le-groupe {
    font-family: $montserrat;
    .top-header .menu .group {
        a {
            color: $c-white;
            &:before { opacity: 1; }
        }
    }

    .columns {
        margin:     0;
        padding:    0;
        p,
        div,
        .text {
            font-size: 1.4rem;
        }
        ol,
        ul {
            padding: 0;
        }
        .column.main {
            //Banner
            .page-banner {
                background:   url("../images/home-banner-bg.jpg") 0 no-repeat;
                background-size: cover;
                height: 420px;
                margin: 0;
            }
            .page-content {
                margin: 40px auto;
                padding: 0 10px;
            }
            .container {
                margin: 0 auto 80px;
                &.no-space {
                    margin: 0 auto;
                    .details,
                    .picture {
                        vertical-align: bottom;
                    }
                    .details {
                        padding: 0 10px 50px;
                    }
                }
                &.value ul { padding: 0 0 0 20px; }
            }
            .block-grey {
                margin: 0 auto;
                padding: 65px 50px;
                .h2-title { margin: 0 0 75px; }
            }
            .block-grey-dark {
                text-align: center;
                font-size: 0;
                .h2-title { margin: 0 0 70px; }
                .item {
                    display: inline-block;
                    width: 25%;
                    padding: 0 20px;
                    text-align: center;
                    vertical-align: top;
                    margin: 0 0 60px;
                    text-transform: uppercase;
                    .text {
                        font-size: 1.1rem; }
                }
            }
        }
    }
    .top-group,
    .bottom-group {
        text-align: center;
        .item {
            border-right: 1px solid #dcdcdc;
            display: inline-block;
            padding: 0 60px 0;
            vertical-align: top;
            text-align: left;
            &:last-child { border:none; }
        }
        .title {
            font-family:$montserrat;
            font-size: 2.0rem;
            color: $c-project2;
        }
        em,
        a {
            color: $c-project3;
            font-size: 1.2rem;
            font-family: $open;
        }
    }
    .top-group {
        margin: 0 0 75px;
        .item {
            width: 35%;
            img { max-width: 200px; }
        }
    }
    .bottom-group {
        .item {
            width: 33%;
        }
    }
}

.cms-agence {
    #store-finder-widget #uberall-h1-custom_title.ubsf_outer-wrap {
        margin-bottom: 50px;
        font-size: 4rem;
    }
}

//
// Responsive
//
@include respond-to(medium) {
    .cms-le-groupe {
        .columns {
            .column.main {
                .block-grey {
                    margin: 0 auto;
                    padding: 65px 20px;
                }
            }
        }
        .top-group {
            .item {
                width: 40%;
                padding: 0 20px;
            }
        }
        .bottom-group {
            .item {
                padding: 0 20px;
                width: 32%;
            }
        }
    }
}
@include respond-to(small) {
    .cms-le-groupe {
        .columns {
            .column.main {
                .block-grey-dark {
                    padding:    65px 20px;
                    .item {
                        display: inline-block;
                        width: 50%;
                        padding: 0 20px;
                        text-align: center;
                        vertical-align: top;
                        margin: 0 0 60px;
                        font-size: 1.1rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .top-group {
            margin: 0;
            .item:last-child {
                border-right: none;
                border-bottom: 1px solid #dcdcdc;
                padding: 0 10px 10px;
                margin: 0 auto 20px;
            }
        }
        .top-group,
        .bottom-group {
            .item {
                width: 100%;
                max-width: 300px;
                display: block;
                border-right: none;
                border-bottom: 1px solid #dcdcdc;
                padding: 0 10px 10px;
                margin: 0 auto 30px;
            }
        }
    }

    .cms-agence {
        #store-finder-widget #uberall-h1-custom_title.ubsf_outer-wrap {
            margin-bottom: 50px;
            font-size: 3rem;
        }
    }
}
