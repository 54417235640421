// =============================================
// Skin of a product item
// =============================================
.web-price{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    .web-price-content{
        display: inline-block;
        margin: -1px auto 0;
        width: 95%;
        max-width:175px;
        height: 30px;
        line-height: 30px;
        background: $c-project10;
        color: $c-white;
        @include border-radius(0 0 5px 5px);
        font-family: $montserrat;
        font-size: 1.5rem;
    }
}
// ENTRETIEN'S PRODUCT ITEM
.product-item {
    position: relative;
    width: 25%;
    display: inline-block;
    font-size: 1.3rem;
    padding: 35px 0;
    background: $c-white;
    border: 1px solid #e3e3e3;
    @include transition(all 250ms ease-in-out);
    vertical-align: top;
    // Hover
    &:hover {
        @include transform(scale(1.1));
        box-shadow: 0 2px 51px rgba(0, 0, 0, 0.14);
        z-index: 2;
    }
    .product-name{
        text-transform: uppercase;
        font-family: $montserrat;
        color: $c-project2;
        font-size: 2.4rem;
        font-weight: bold;
        margin: 0 0 25px 0;
        span{
            font-weight: normal;
            display: block;
            font-size: 1.4rem;
            color: tint($c-project2, 50%);
            margin: 10px 0 0 0;
        }
    }
    .price-box {
        margin: 0 0 20px 0;
        .price{
            font-family: $montserrat;
            font-size: 3.4rem;
            font-weight: normal;
            color: $c-project5;
        }
        .price-excluding-tax{
            display: none;
        }
    }
    .per-month{
        margin: 0;
        font-size: 1.4rem;
        color: tint($c-project2, 50%);
    }
    .listactions{
        text-align: left;
        li{
            padding: 7px 7px 7px 45px;
            font-size: 1.3rem;
            color: $c-project2;
            position: relative;
            .icon{
                font-size: 1.1rem;
                color: $c-project10;
                position: absolute;
                left: 17px;
                top: 11px;
            }
            &:nth-child(odd){
                background: #f9f9f9;
            }
            &.not-included{
                color: tint($c-project2, 50%);
            }
        }
    }
    .buttons-set{
        margin: 35px 0 0 0;
        padding: 0 10px;
        .button{
            max-width: 205px;
            margin: 0 auto;
        }
    }
}
    .head-product{
        padding: 5px 0 35px 0;
    }

.product-item-infos {
    padding: 30px 0 0 0;
    min-height: 145px;
}
.product-name {
    font-size: 1.4rem;
}


// PRODUCT ITEM GLOBAL
.item-product {
    border: 1px solid $c-project7;
    position: relative;
    padding: 20px;
    text-align: center;
    font-family: $montserrat;
    &:before {
        content:"";
        background-color: $c-white;
        height: 1px;
        left: 20%;
        right: 20%;
        position: absolute;
        top: -1px;
    }
    &:after {
        content:"";
        background-color: $c-white;
        height: 1px;
        left: 20%;
        right: 20%;
        position: absolute;
        bottom: -1px;
    }
    .sticky-promo {
        display: block;
        background: $c-project1;
        color: $c-white;
        height: 53px;
        width: 52px;
        @include border-radius(100%);
        position: absolute;
        right: 20px;
        top: 20px;
        line-height: 50px;
    }
    img { max-width: 205px; }
    .type {
        text-transform: uppercase;
        color: $c-project4;
        font-size: 1.2rem;
    }
    .product-name {
        font-size: 1.4rem;
        color: $c-black;
        text-transform: uppercase;
    }
    .price {
        font-family: $montserrat;
        font-size: 1.2rem;
        margin: 15px 0;
        color:  $c-project3;
    }
    .special-price,
    .old-price {
        display: inline-block;
        margin: 0 5px;
    }
    .special-price {
        color: $c-project1;
        font-size: 1.6rem;
    }
    .old-price {
        position: relative;
        &:after {
            content:"";
            background: #000;
            height: 1px;
            display: block;
            position: absolute;
            top: 8px;
            width: 100%;
        }
    }
}


@include respond-to(medium){
    .product-item{
        .product-name{
            font-size: 2rem;
        }
        .price-box {
            .price{
                font-size: 3rem;
            }
            .old-price .price{
                font-size: 2rem;
            }
        }
        .buttons-set{
            .button{
                width: 100%;
                padding: 0;
                font-size: 1.1rem;
            }
        }
    }
}

@include respond-to(small){
    .web-price{
        left: auto;
        .web-price-content{
            width: 95px;
            height: 25px;
            line-height: 25px;
            @include border-radius(0 0 0 5px);
            font-size: 1.3rem;
        }
    }
    .product-item {
        width: auto;
        display: block;
        padding: 15px 0 20px;
        @include transition(none);
        margin: 0 0 20px 0;
        &:last-child{
            margin: 0;
        }
        // Hover
        &:hover {
            @include transform(scale(1));
            box-shadow: none;
        }
        .product-name{
            font-size: 1.6rem;
            margin: 0 0 5px 0;
            span{
                display: none;
            }
        }
        .price-box {
            display: inline-block;
            vertical-align: bottom;
            margin: 0;
            .price{
                font-size: 2.6rem;
            }
        }
        .per-month{
            display: inline-block;
            vertical-align: bottom;
            margin: 0 0 3px 5px;
        }
        .listactions{
            overflow-x: auto;
            padding: 15px 0 0;
            font-size: 0;
            width: 770px;
            li{
                padding: 0px 10px;
                display: inline-block;
                vertical-align: top;
                font-size: 1.2rem;
                width: 110px;
                text-align: center;
                .icon{
                    position: relative;
                    top: 0;
                    left: 0;
                    display: block;
                    margin: 0 auto 7px;
                }
                &:nth-child(odd){
                    background: none;
                }
                &.not-included{
                    color: tint($c-project2, 50%);
                }
            }
        }
        .buttons-set{
            margin: 25px 0 0 0;
        }
    }
    .head-product{
        padding: 5px 15px 15px;
        text-align: left;
        border-bottom: 1px solid #e3e3e3;
    }
    .product-name {
        font-size: 1.4rem;
    }
}

