// =============================================
// Page 404 / 503
// =============================================
.cms-no-route {
    .column.main {
        max-width:  870px;
        margin:     150px auto 300px;
        padding:    0 10px;
        text-align: center;
        h1{
            margin: 0 0 80px;
        }
        .button {
            display:    inline-block;
            margin:     0 10px;
            width:      auto;
        }
    }
}

//
// Responsive
//
@include respond-to(medium) {
    .cms-no-route {
        .column.main {

        }
    }
}
@include respond-to(small) {
    .cms-no-route {
        .column.main {

        }
    }
}