// =============================================
// Fancybox Base
// =============================================
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image {
    padding:        0;
    margin:         0;
    border:         0;
    outline:        none;
    vertical-align: top;
}
.fancybox-wrap {
    iframe,
    object {
        padding:        0;
        margin:         0;
        border:         0;
        outline:        none;
        vertical-align: top;
    }
}
.fancybox-nav {
    padding:        0;
    margin:         0;
    border:         0;
    outline:        none;
    vertical-align: top;
    span {
        padding:        0;
        margin:         0;
        border:         0;
        outline:        none;
        vertical-align: top;
    }
}
.fancybox-tmp {
    padding:        0;
    margin:         0;
    border:         0;
    outline:        none;
    vertical-align: top;
}
.fancybox-wrap {
    position: absolute;
    top:      0;
    left:     0;
    z-index:  8020;
}
.fancybox-skin {
    position:              relative;
    background:            #f9f9f9;
    color:                 #444444;
    text-shadow:           none;
    -webkit-border-radius: 4px;
    -moz-border-radius:    4px;
    border-radius:         4px;
}
.fancybox-opened {
    z-index: 8030;
    .fancybox-skin {
        -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
        -moz-box-shadow:    0 10px 25px rgba(0, 0, 0, 0.5);
        box-shadow:         0 10px 25px rgba(0, 0, 0, 0.5);
    }
}
.fancybox-outer { position: relative; }
.fancybox-inner {
    position: relative;
    overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner { -webkit-overflow-scrolling: touch; }
.fancybox-error {
    color:       #444444;
    font:        14px / 20px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin:      0;
    padding:     15px;
    white-space: nowrap;
}
.fancybox-image, .fancybox-iframe {
    display: block;
    width:   100%;
    height:  100%;
}
.fancybox-image {
    height: auto;
    display: block;
    margin: 0 auto;
}

#fancybox-loading {
    position:            fixed;
    top:                 50%;
    left:                50%;
    margin-top:          -22px;
    margin-left:         -22px;
    cursor:              pointer;
    z-index:             8060;
    div {
        @include border-radius(10px);
        width:      44px;
        height:     44px;
        background: #fff url('../images/ajax-loader.gif') center center no-repeat;
    }
}
.fancybox-close {
    position: absolute;
    top:      -18px;
    right:    -18px;
    width:    36px;
    height:   36px;
    cursor:   pointer;
    z-index:  8040;
}
.fancybox-nav {
    position:                    absolute;
    top:                         0;
    width:                       40%;
    height:                      100%;
    cursor:                      pointer;
    text-decoration:             none;
    background:                  transparent url('../images/fancybox/blank.gif');
    ///* helps IE */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index:                     8040;
}
.fancybox-prev { left: 0; }
.fancybox-next { right: 0; }
.fancybox-nav span {
    position:   absolute;
    top:        50%;
    width:      36px;
    height:     34px;
    margin-top: -18px;
    cursor:     pointer;
    z-index:    8040;
    visibility: hidden;
}
.fancybox-prev span {
    left:                10px;
    background-position: 0 -36px;
}
.fancybox-next span {
    right:               10px;
    background-position: 0 -72px;
}
.fancybox-nav:hover span { visibility: visible; }
.fancybox-tmp {
    position:   absolute;
    top:        -99999px;
    left:       -99999px;
    visibility: hidden;
    max-width:  99999px;
    max-height: 99999px;
    overflow:   visible !important;
}
///* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width:    auto;
    body { overflow: hidden !important; }
}
.fancybox-lock-test { overflow-y: hidden !important; }
.fancybox-overlay {
    position:   absolute;
    top:        0;
    left:       0;
    overflow:   hidden;
    display:    none;
    z-index:    999;
    background: rgba(0,0,0,0.6);
}
.fancybox-overlay-fixed {
    position: fixed;
    bottom:   0;
    right:    0;
}
.fancybox-lock .fancybox-overlay {
    overflow:   auto;
    overflow-y: scroll;
}
///* Title helper */

.fancybox-title {
    visibility:  hidden;
    font:        normal 13px / 20px "Helvetica Neue", Helvetica, Arial, sans-serif;
    position:    relative;
    text-shadow: none;
    z-index:     8050;
}
.fancybox-opened .fancybox-title { visibility: visible; }
.fancybox-title-float-wrap {
    position:      absolute;
    bottom:        0;
    right:         50%;
    margin-bottom: -35px;
    z-index:       8050;
    text-align:    center;
    .child {
        display:               inline-block;
        margin-right:          -100%;
        padding:               2px 20px;
        background:            transparent;
        ///* Fallback for web browsers that doesn't support RGBa */
        background:            rgba(0, 0, 0, 0.8);
        -webkit-border-radius: 15px;
        -moz-border-radius:    15px;
        border-radius:         15px;
        text-shadow:           0 1px 2px #222222;
        color:                 #ffffff;
        font-weight:           bold;
        line-height:           24px;
        white-space:           nowrap;
    }
}
.fancybox-title-outside-wrap {
    position:   relative;
    margin-top: 10px;
    color:      #ffffff;
}
.fancybox-title-inside-wrap { padding-top: 10px; }
.fancybox-title-over-wrap {
    position:   absolute;
    bottom:     0;
    left:       0;
    color:      #ffffff;
    padding:    10px;
    background: #000000;
    background: rgba(0, 0, 0, 0.8);
}
//FancyBox Thumbs

#fancybox-thumbs {
    position: fixed;
    left:     0;
    width:    100%;
    overflow: hidden;
    z-index:  8050;
    padding-top: 8px;
    &.bottom { bottom: 25px; }
    &.top { top: 2px; }
    ul {
        position:   relative;
        list-style: none;
        margin:     0;
        padding:    0;
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
        text-align: center;
        li {
            display: inline-block;
            background: $c-white;
            margin-right: 10px;
            &.active {
                a{
                    box-shadow: 0px 0px 10px 1px $c-grey;
                    img {
                        @include transition(all 0.2s ease-out);
                        transform: scale(0.8);
                    }
                }
            }
            a{
                display: block;
                line-height: 0;
                outline: none;
                @include transition(all 0.250s ease-in-out);
                &:hover{
                    box-shadow: 0px 0px 10px 1px $c-grey;
                    img {
                        @include transition(all 0.2s ease-out);
                        transform: scale(0.8);
                    }
                }
            }
            img {
                @include transition(opacity .250s linear);
                display:   block;
                position:  relative;
                border:    0;
                padding:   0;
                max-width: none;
            }
        }
    }
}

.zoom-popin{
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    .fancybox-skin {
        box-shadow: none;
    }
    .fancybox-inner{
        width: 100% !important;
        margin: 0 auto;
    }
}

@include respond-to(small) {
    #fancybox-thumbs {
        &.bottom {
            bottom: auto;
            top: 400px;
        }
    }
}