// =============================================
// Menu
// =============================================
body {
    overflow-y: initial;
    position: relative;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background: $c-grey-1;
        opacity: 0;
    }
}

//Block body scroll
@include breakpoint(only-sm) {
    body:has(.navigation.active) {
        overflow-y: hidden;
        

        &::before {
            opacity: .7;
            z-index: 10;
            height: 100vh;
            transition: opacity .3s .3s linear;
        }
    }
}


//Mobile
.header.content {
    position: relative;

    .navigation {
        overflow: scroll;
        position: fixed;
        top: 0;
        left: 0;
        width: 90vw;
        height: 100vh;
        background-color: $c-white;
        z-index: 99;
        transform: translateX(-200%);
        transition: transform .5s linear;

        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        padding: 40px;

        &.active {
            transform: translateX(0);
        }
    }
    
} 

//
// Panel (top header)
//
.header.content .navigation .header-top {
    order: 2;
    
    .header-top-left {
        padding-top: 30px;
       
        .menu {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            //ToDO: handle active class
            li:first-child {
                a.link {
                   color: $c-main;
                   border-bottom: 2px solid $c-main;
                }
            }

            li:last-child {
                flex-basis: content;
            }

            li {
                width: calc(50% - 30px);
                padding-right: 20px;

                a.link {
                    font-size: 1.5rem;
                    font-family: $roboto-m;
                    color: $c-grey-1;
                }
            }

            
        } 
    }
    

    .header-top-right {
        height: 150px;

        .reviews-widget__container {
            margin-top: 40px;
        }
    }
}

//
//Header Menu
//
header .prx-banner {

    .menu-content {
        order: 1;
        
        .svg-logo svg{
            width: 200px;
            height: auto;
        }

        ul {
            font-family: $roboto-b;
            
            .link { 
                font-size: 2.2rem;
                color: $c-grey-2;
                margin: 40px 0;
            }

            .btn {
                margin: 10px 0;
            }
        }
    }
}



// =============================================
// Mobil Icon
// =============================================
.menu-mob {
    position: fixed;
    top: 70px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 200px;
    box-shadow: 0px 10px 15px rgba(12, 35, 44, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-white;
    z-index: 100;

    label {
        display:flex;
        flex-direction:column;
        width:25px;
        cursor:pointer;

        input[type="checkbox"]{
            display:none;
        }

        span {
            background: $c-main;
            border-radius:10px;
            height:3px;
            margin: 3px 0;
            transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span:nth-of-type(1){
            width:50%;
        }
          
        span:nth-of-type(2){
            width:100%;
        }
          
          
        span:nth-of-type(3){
            width:75%;
        }

        // Input checket
        input[type="checkbox"]:checked {
            ~ span:nth-of-type(1){
                transform-origin:bottom;
                transform:rotatez(45deg) translate(4px,0px)
            }

            ~ span:nth-of-type(2){
                transform-origin:top;
                transform:rotatez(-45deg)
            }

            ~ span:nth-of-type(3){
                transform-origin:bottom;
                width:50%;
                transform: translate(11px,-6px) rotatez(45deg);
            }
        }   
    }
}


//
// Responsive
//
/* ----------------- Tablette 800px
*/
@include breakpoint(sm) {

    //Remove mobile
    .header.content .navigation {
        position: initial;
        width: initial;
        height: initial;
        display: inline;
        padding: 0;

        .header-top,
        .menu-content {
            padding: 0 20px;
        }
    }
    
    .menu-mob {
        display: none;
    }

    //Header Top
    .header.content .navigation .header-top {
        display: flex;
        justify-content: space-between;

        //Navigation side
        .header-top-left {
            padding-top: 0px;

            .menu {
                flex-wrap: nowrap;
                gap: 10px;

                //ToDO: handle active class
                li:first-child {
                    background-color: $c-main;
                        
                    a.link {
                        color: $c-white;
                        border-bottom: none;
                    }
                }

                li:not(:first-child ) {
                    &:hover {
                        a.link {
                            color: $c-main;
                        }
                    }
                }

                //link common style
                li { 
                    width: auto;
                    padding-right: 0;
                    border-radius:0 0 10px 10px;
                    box-shadow: 0px 10px 15px rgba(12, 35, 44, 0.1);

                    a.link {
                        padding: 15px;
                        display: block;
                        line-height: 16px;
                        font-size: 1.4rem;
                        font-family: $roboto;
                    }   
                }
            }
            
        }

        .header-top-right {
            
            .reviews-widget__container {
                display: none;
            }
        }
    }

    //Menu header
    header .prx-banner {
        
        .menu-content,
        .menu-content ul {
            display: flex;
            align-items: center;
        }

        .menu-content {
            margin-bottom: 30px;
            gap: 8%;
            justify-content: space-between;

            ul {
                gap: 10px;
                justify-content: flex-end;
                flex-wrap: wrap;

                .link,
                .btn {
                    margin: 0 0;
                }

                .link { 
                    font-size: 1.8rem;
                    
                    a {
                        margin-right: 2vw;
                    }
                }

                .btn {
                    &:not(.btn__account) {
                        padding: 20px 20px;
                    }

                    &.btn__blue:hover {
                        background-color: $c-main;
                    }

                    .btn-label {
                        font-size: clamp(1.2rem, 1vw, 1.5rem);
                    }
                }
            }
        }
    }
}

/* ----------------- Screen 1024px
*/
@include breakpoint(lg) {
    header .prx-banner {
        .menu-content {
            ul {
               justify-content: space-between;
               flex-wrap:nowrap;
            }
        }
    }

    //Header Top
    .header.content .navigation .header-top {
        .header-top-right {
            .reviews-widget__container {
                display: flex;
                margin-top: 0px;
                border-radius: 0px 0px 10px 5px;
            }
        }
    }
}
