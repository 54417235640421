//
// Messages
// Error,Notice,Warning,Success
//

$widthMessageImage:    53px;
$heightMessageImage:   53px;

// Content
.page.messages {
    max-width: 600px;
    margin:    0 auto ;
    .messages {
        padding: 0 10px;
        position:   relative;
        .message-content {
            margin: 20px 0;
            text-align: center;
        }
        .error-msg,
        .message-error,
        .notice-msg,
        .warning-msg,
        .success-msg {
            border:     1px solid;
            margin:     50px 0;
            padding:    15px 20px;
            @include border-radius(5px);
            background-repeat: no-repeat;
            .message-content {
                color:          $c-project2;
                padding:        0 0 0 20px;
                text-align:     left;
                display:        inline-block;
                vertical-align: middle;
                width:          80%;
            }
            &:before {
                content:        "";
                display:        inline-block;
                height:         $heightMessageImage;
                width:          $widthMessageImage;
                vertical-align: middle;
            }
        }
        .error-msg, .message-error {
            background-color:   lighten($c-error, 32%);
            border-color:       $c-error;
            &:before {
                background: url("../images/picto-error.png");
            }
        }
        .notice-msg {
            background-color:   lighten($c-warning, 32%);
            border-color:       $c-warning;
            &:before {
                background: url("../images/picto-notice.png");
            }
        }
        .success-msg {
            background-color:   lighten($c-success, 32%);
            border-color:       $c-success;
            &:before {
                background: url("../images/picto-success.png");
            }
        }
        .warning-msg { background-color: $c-warning; }
    }
}

.contentmanager-contentlist-view {
    .message.info.empty {
        background: $c-white;
        position: absolute;
        top: 42%;
        left: 50%;
        @include transform(translate(-50%));

    }
}
.message.info.empty {
    font-size: 2rem;
    padding: 50px 20px;
    text-align: center;
    div {
        display: inline-block;
        padding: 0 0 0 70px;
        position: relative;
        &:before {
            background: url("../images/picto-error.png");
            content:        "";
            display:        block;
            height:         $heightMessageImage;
            width:          $widthMessageImage;
            position: absolute;
            top: -15px;
            left: 0;
        }
    }
}


@include respond-to(small) {
    .page.messages {
        .messages {
            .error-msg,
            .message-error,
            .notice-msg,
            .warning-msg,
            .success-msg {
                .message-content {
                    padding: 0;
                }
            }
        }
    }

}