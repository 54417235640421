// =============================================
// Page Data input (Saisie des indexs)
// =============================================

$c-bg-data-input: #eaeaea;

.proxiserve-professional-datainput-index,
.proxiserve_professional-datainput-index {

  *:-webkit-autofill {
    border-bottom: none !important;
  }

  .data-input-header {
    padding: 0 0 15px;
    background: $c-bg-data-input;

    .container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 960px;
      margin: 0 auto;

      @include respond-to(small) {
        flex-direction: column-reverse;
      }

      .image,
      .txt {
        width: 50%;

        @include respond-to(small) {
          width: 100%;
        }
      }

      .image {
        position: relative;
        overflow: hidden;
        height: 400px;

        @include respond-to(small) {
          flex-direction: column-reverse;
          width: 280px;
          height: 230px;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          border: 400px solid $c-bg-data-input;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          width: 1260px;
          height: 1270px;
          transform: translate(-50%, -50%);

          @include respond-to(small) {
            width: 600px;
            height: 600px;
            border: 160px solid $c-bg-data-input;
          }
        }

        img {
          margin: 0 auto;
          width: 100%;
        }
      }

      .txt {
        padding: 20px 30px 20px 0;

        @include respond-to(small) {
          padding: 20px;
        }

        h1 {
          margin: 0 0 1em;
          padding: 4px 12px;
          background: #e43117;
          color: #fff;
          display: inline-block;
          font-size: 2rem;
        }
        .description {
          font-size: 1.6rem;
        }
      }
    }
  }

  .data-input-content {
    padding: 100px 5px 20px 5px;
    margin: 0 auto;
    width: 100%;
    max-width: 960px;

    .accommodation {
      input {
        border: none;
      }
    }

    .data-input-date {
      position: relative;
    }

    .meter {
      &>div {
        margin-bottom: 30px;
      }
      input {
        border: none;
      }
      margin-bottom: 20px;
      .data-meter-name {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        color: $c-black;
      }

      &.hot-water-meter {
        color: red;
      }
      &.cold-water-meter {
        color: blue;
      }

      div {
        vertical-align: text-top;
      }

      .field {
        margin: 0 auto;
        width: 80%;
        margin-bottom: 30px;
        
        @media screen and (min-width:800px) {
          display: inline-block;
          width: 24%;
          margin-bottom: 0;
        }
      }
    }

    .field-row.hide {
      padding: 0;
      border: none;

      input {
        opacity: 0;
      }


    }

    .button {
      height: auto;
      padding: 10px 25px;
      margin: 0;
      font-size: 1.4rem;
      border-radius: 0;
    }
  }

}

//
// Success Page
//

.proxiserve_professional-datainput-index {

  #maincontent {
    position: relative;
    padding-bottom: 100px;
  }

  .page.messages {
    position: absolute;
    bottom: 0;
    max-width: 100vw;
    width: 100vw;

    .message-success {
      padding-top: 60px;
      padding-bottom: 25px;
      position: absolute;
      bottom: 0;
      max-width: 960px;
      margin: 0 auto;
      color: $c-success;
      text-align: center;
      font-size: 1.6rem;
      left: 50%;
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 100%;
        background: url(../images/professionnels/saisie-index/mail.svg) center top no-repeat;
        background-size: 100px;
      }
    }
  }
}

//SUCCESS
.proxiserve_professional-datainput-success {
  .page-main {
    margin: 50px auto
  }

  .success-img {
      width: 350px; 
      margin: 100px auto 25px;

      svg {
          width: 100%; 
          height: 100%;

          path.outside {
              stroke-dasharray: 400px;
              stroke-dashoffset: 400px;
              animation: anim .5s ease-in forwards;
          }

          path.inside-top {
              stroke-dasharray: 120px;
              stroke-dashoffset: 120px;
              animation: anim .5s ease-in forwards;
          }
          path.inside,
          path.inside,
          path.wind,
          path.wind {
              stroke-dasharray: 70px;
              stroke-dashoffset: 70px;
              animation: anim .5s ease-in forwards;
          }
          path.circle {
              stroke-dasharray: 170px;
              stroke-dashoffset: 170px;
              animation: anim .3s .4s ease-in forwards;
          }
          path.check {
              stroke-dasharray: 50px;
              stroke-dashoffset: 50px;
              animation: anim .3s .7s ease-in forwards;
          }
      }

      @keyframes anim {
          to {
              stroke-dashoffset: 0;
          }
      }
  }

  .success-message {
      width: 80%;
      margin: 0px auto 50px;
      text-align: center;
      font-size: 2.5rem;
  }
}
//
// Responsive
//

@include respond-to(small) {

  .proxiserve-professional-datainput-index,
  .proxiserve_professional-datainput-index  {

    .page.messages .message-success {
      width: 90vw;
      padding-top: 40px;
      font-size: 1.4rem;
      
      &::before {
        background-size: 75px;
      }
    }

    .data-input-header {
      padding: 5px;
      .container {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        .image {
          background-size: 100%;
          margin: 0 auto;
          float: none;
        }
        .txt {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    .data-input-content {
      width: 100%;
      .accommodation {
        .input-text {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }

}